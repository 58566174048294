import React from 'react';
import helpers from '../../services/helpers';
import axios from 'axios';
// import SelectAsync from 'react-select/lib/Async';
import MUIDataTable from 'mui-datatables';

// import SelectionInput from '../common/input/selectionInput';
import { Card, Button, Badge, Dropdown, Modal, Container, Tab, Breadcrumb, Row, Col, Fade, ProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, IconButton } from '@material-ui/core';
import DropdownIcon from '../common/dropdownIcon';
import NodeForm, { NodeFormData } from '../common/nodeForm';
import validation from '../../services/validation';
import auth from '../../services/authentication';
import SpinnerOverlay from '../common/spinnerOverlay';
import ProtectedButton from '../common/protectedButton';

const options:any = {
    textLabels: helpers.getDatatableLocalization(),
    print:false,
    download:false,
    filter:true,
    search:true,
    viewColumns:false,
    elevation: 0,
    customToolbarSelect: ()=><></>,
};

class AcessoNivel extends React.Component<any,any> {
    _mounted=false;
    state:any = {
        editingNivel: null,
        nivelModalOpened:false,
        currentNivelTab: "_def",
        nivelDefValid: false,
        nivelDef: null,
        nivelAcessos: null,

        lastRefresh: 0,
        nivelData:[],
        acessoData:[],
        acessoNivelData: [],

        fetchingData: false,

        spinnerOverlay: false,
        spinnerStatus: null
    }

    delayedInputCheck:any=null;
    nivelForm:NodeFormData = {
        fields:[
            {label:"Nome", name:"nome", type: "text", placeholder: "Ex.: Administrador", defaultValue: "", validation: validation.required},
        ],
        buttons: [
            {label: "Próximo", variant: "primary", isSubmit: true, action: (form: NodeForm)=>{
                const allValid = form.validateAll();
                if(allValid){
                    const values = form.getValues();
                    if(this._mounted)this.setState({new_nivelDef: values});
                    this.goToNivelTab("_acesso");
                } else {
                    form.highlightInvalid();
                }
                if(this._mounted)this.setState({nivelDefValid: allValid});
            }},
            {label: "Cancelar", variant: "secondary", action: (form: NodeForm)=>{
                this.closeNivelModal();
            }}
        ],
        buttonClasses:"offset-1",
        onChange: (form: NodeForm)=>{
            if(this.delayedInputCheck) clearTimeout(this.delayedInputCheck);
            this.delayedInputCheck = setTimeout(()=>{
                const values = form.getValues();
                if(this._mounted)this.setState({new_nivelDef: values, nivelDefValid: form.validateAll(true)});
            },1000);
        }
    }

    selectAccessOptions:any = {
        ...options,
        filter:false,
        selectableRows: true,
        rowsSelected: [],
        customToolbarSelect: (selectedRows:any)=><>
            <Tooltip className="mr-2" title={"Confirmar"}>
                <IconButton className={""} onClick={()=>{
                    const data = selectedRows.data.map((s:any)=>this.state.acessoData[s.dataIndex]);
                    this.selectAccessOptions.rowsSelected = selectedRows.data.map((s:any)=>s.index);
                    this.setState({new_nivelAcessos: data});
                    this.goToNivelTab("_confirm");
                }}>
                    <FontAwesomeIcon icon="check" style={{width:"20px",height:"20px"}}/>
                </IconButton>
            </Tooltip>
        </>,
        onRowsSelect: (currentSelected:Array<any>,allSelected:Array<any>)=>{
            const data = allSelected.map((s:any)=>this.state.acessoData[s.dataIndex]);
            this.selectAccessOptions.rowsSelected = allSelected.map((s:any)=>s.index);
            this.setState({new_nivelAcessos: data});
        }
    };

    updateRemoteData = (refresh?:boolean)=>{
        if(this._mounted) this.setState({fetchingData:true});
        helpers.backendGet('acessoNivel/acessoNivel',null,refresh?false:true).then((response)=>{
            if(this._mounted) this.setState({ 
                fetchingData: false,
                lastRefresh: Date.now(),
                nivelData: response.data.niveis,
                acessoNivelData: response.data.acessoNivel,
                acessoData: response.data.acesso
            });
        }).catch(err=>{
            auth.fetchSession();
            if(this._mounted){
                setTimeout(()=>{if(this._mounted){
                    this.setState({fetchingData: false});
                    if(err.response){
                        this.props.alertError(<>Erro {err.response.status}: {err.response.statusText}<br/><br/>{err.response.data}</>);
                    } else {
                        this.props.alertError(<>{err.toString()}</>);
                    }
                }}, 1500);
            };
        });
    }

    componentDidMount(){
        this._mounted=true;
        this.updateRemoteData(true);
    }
    componentWillUnmount() {this._mounted=false;}

    validateNivel = ()=>{
        const {nivelDefValid,new_nivelAcessos} = this.state;
        return nivelDefValid;
    }
    sendNivel = ()=>{
        if(this._mounted) this.setState({spinnerOverlay: true, spinnerStatus: null});
        const data:any = {
            ...this.state.new_nivelDef,
            action: this.state.editingNivel?'update':'insert',
            id: this.state.editingNivel,
            acesso: this.state.new_nivelAcessos && this.state.new_nivelAcessos.map((acesso:any)=>acesso.id)
        }
        axios.post(helpers.backendPath('nivel/nivel'),data,{...auth.getAuthOptions()}).then(response=>{
            if(this._mounted){
                this.setState({spinnerStatus: "success"});
                this.updateRemoteData(true);
                auth.fetchSession();
                setTimeout(()=>{
                    if(this._mounted) this.setState({spinnerOverlay: false});
                    this.closeNivelModal();
                }, 1500);
            }
        }).catch(err=>{
            auth.fetchSession();
            if(this._mounted){
                this.setState({spinnerStatus: "error"});
                setTimeout(()=>{if(this._mounted){
                    this.setState({spinnerOverlay: false});
                    if(err.response){
                        this.props.alertError(<>Erro {err.response.status}: {err.response.statusText}<br/><br/>{err.response.data}</>);
                    } else {
                        this.props.alertError(<>{err.toString()}</>);
                    }
                }}, 1500);
            };
        });
    }

    openNivelModal = (editID?:any)=> {
        // Reseta os formularios
        this.nivelForm.fields[0].defaultValue = "";
        let nivelDef = null;
        let nivelAcessos = null;
        this.selectAccessOptions.rowsSelected=[];

        if(editID){ // Se estiver editando, carrega os valores do item
            const itemData = this.state.nivelData.find((i:any)=>i.id===editID);
            const nivelAcessoList = this.state.acessoNivelData.filter((acesso:any)=>acesso.nivel===editID);
            this.nivelForm.fields[0].defaultValue = itemData.nome;
            nivelDef = {nome: itemData.nome};
            nivelAcessos = this.state.acessoData.filter((acesso:any)=>nivelAcessoList.find((link:any)=>link.acesso==acesso.id));
            this.selectAccessOptions.rowsSelected = nivelAcessos.map((acesso:any)=>this.state.acessoData.indexOf(acesso));
        }

        if(this._mounted) this.setState({editingNivel: editID?editID:null,nivelModalOpened: true, currentNivelTab: "_def", new_nivelDef: nivelDef, new_nivelAcessos: nivelAcessos, nivelDefValid: editID?true:false});
    }
    closeNivelModal = ()=>{if(this._mounted)this.setState({nivelModalOpened: false})};
    goToNivelTab = (tabName:string)=> this.setState({currentNivelTab: tabName});

    deleteNivel = (id:any)=>{
        if(this._mounted) this.setState({spinnerOverlay: true, spinnerStatus: null});
        
        const data:any = { id: id };
        axios.delete(helpers.backendPath('nivel/nivel'),{params: data,...auth.getAuthOptions()}).then(async response=>{
            if(this._mounted){
                this.setState({spinnerStatus: "success"});
                this.updateRemoteData(true);
                auth.fetchSession();
                setTimeout(()=>{if(this._mounted) this.setState({spinnerOverlay: false})}, 1500);
            }
        }).catch((err)=>{
            auth.fetchSession();
            if(this._mounted){
                this.setState({spinnerStatus: "error"});
                setTimeout(()=>{if(this._mounted){
                    this.setState({spinnerOverlay: false});
                    if(err.response){
                        this.props.alertError(<>Erro {err.response.status}: {err.response.statusText}<br/><br/>{err.response.data}</>);
                    } else {
                        this.props.alertError(<>{err.toString()}</>);
                    }
                }}, 1500);
            };
        });
    }

    render() {
        const nivelOptions:any = {
            ...options,
            selectableRows: false,
            customToolbar: ()=><>
                <ProtectedButton accessPath="/acessoNivel/cadastro" className="ml-4 mt-2" variant="success" size={"sm"} style={{float:"right"}} onClick={()=>this.openNivelModal()}>Novo Nível</ProtectedButton>
            </>,
        }
        const blank={options:{empty:true,filter:false,sort:false,searchable:false,viewColumns:false,print:false,download:false}};

        const nivelActionOptions = [
            {label: "Editar", onClick:(ref:any)=>{
                this.openNivelModal(this.state.nivelData[ref.rowIndex].id);
            }},
            {label: "Remover", onClick:(ref:any)=>{
                const item = this.state.nivelData[ref.rowIndex];
                let alertContent = {
                    title: `Remover Nível (Cod.${item.id})`,
                    body: <p>Tem certeza que deseja remover o nível "{item.nome}"?</p>,
                    buttons: [
                        {label: "REMOVER", variant: "danger", action: ()=>{this.deleteNivel(item.id); this.props.closeAlertModal();}},
                        {label: "Cancelar", variant: "secondary", action: this.props.closeAlertModal},
                    ]
                }
                this.props.alertModal(alertContent);
            }}
        ];

        const nivelColumns:any = [
            {label: 'Codigo',name: 'id'},
            {label: 'Nome',name: 'nome'},
            blank,blank,blank,blank,blank,
            (auth.checkPermission('/acessoNivel/cadastro') ? {label: 'Ação',options:{
                customBodyRender: (value:any,tableMeta:any)=><DropdownIcon id={tableMeta.rowIndex} refValue={tableMeta} items={nivelActionOptions}/>
            }}:blank)
        ];
        const acessoColumns:any = [
            {label: 'Codigo',name: 'id'},
            {label: 'Nome',name: 'label'},
            {label: 'Acesso',name: 'path'},
            {label: 'Icone',name: 'faIcon',options:{customBodyRender:(value:any)=>value && <FontAwesomeIcon icon={value} style={{width:"15px",height:"15px"}}></FontAwesomeIcon>}},
            {label: 'Item no Menu',name: 'displayMenu',options:{customBodyRender:(value:any)=><Badge variant={value==="1"?"primary":"secondary"} style={{fontSize:"small"}}>{value==="1"?"Sim":"Não"}</Badge>}},
            {label: 'Dependencia',name: 'dependencia',options:{customBodyRender:(value:any)=><Badge variant="light" style={{fontSize:"small"}}>{value && this.state.acessoData.find((acesso:any)=>acesso.id===value).label}</Badge>}},
        ];
        
        return <>
            <Card className="mt-4" >
                <Fade in={this.state.fetchingData}><div>
                    <ProgressBar style={{height:"8px"}} animated now={100}/>
                </div></Fade>
                <MUIDataTable key={this.state.lastRefresh} title={"Níveis de Acesso"} columns={nivelColumns} data={this.state.nivelData} options={nivelOptions}/>
            </Card>
            {/* this.state.currentNivelTab==="_acesso"?"fullscreen-modal":"" */}
            <Modal centered show={this.state.nivelModalOpened} hidden={this.props.alertModalOpened} dialogClassName={"fullscreen-modal"} onHide={this.closeNivelModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.state.editingNivel?`Editando Nível: ${this.state.nivelData.find((nivel:any)=>nivel.id==this.state.editingNivel).nome}`:"Novo Nível"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Tab.Container activeKey={this.state.currentNivelTab} onSelect={(key:string) => this.setState({ currentNivelTab: key })} id="modal-nivel-tab">
                            <Breadcrumb>
                                {
                                    [{key:'_def',label:'Definição'},
                                     {key:'_acesso',label:'Acesso'},
                                     {key:'_confirm',label:'Confirmar'}
                                    ].map(i=><Breadcrumb.Item key={i.key} onClick={()=>this.setState({currentNivelTab: i.key})} active={this.state.currentNivelTab===i.key}>{i.label}</Breadcrumb.Item>)
                                }
                            </Breadcrumb>
                            <Tab.Content>
                                <Tab.Pane eventKey="_def">
                                    <NodeForm formData={this.nivelForm}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="_acesso">
                                    <MUIDataTable title={"Acesso"} columns={acessoColumns} data={this.state.acessoData} options={this.selectAccessOptions}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="_confirm">
                                    <Row>
                                        <Col className="col-2"><b>Nome:</b></Col>
                                        <Col className="text-secondary">{this.state.new_nivelDef && this.state.new_nivelDef.nome}</Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-2"><b>Acessos:</b></Col>
                                        <Col className="text-secondary">{this.state.new_nivelAcessos && this.state.new_nivelAcessos.map((val:any)=><span key={val.id}>{val.label}<br/></span>)}</Col>
                                    </Row>
                                    <Button className="mt-2 col-12" variant="primary" disabled={!this.validateNivel()} onClick={this.sendNivel}>Confirma</Button>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* <SelectionInput multiple onChange={this.onChange} optionsSource={async()=>(await helpers.backendGet('acessoNivel/acessoNivel')).data.acesso.map((acesso:any)=>({label: acesso.label, value: acesso.id}))}/> */}

            {this.state.spinnerOverlay ? <SpinnerOverlay status={this.state.spinnerStatus}/> : null }
        </>
    }
}
 
export default AcessoNivel;