import React from 'react';
import {Form} from 'react-bootstrap';

class CheckboxInput extends React.Component<any,any> {

    handleChange = (evt:any)=>{
        if(this.props.onChange){
            let newobj = {...this.props};
            newobj.value = evt.currentTarget.checked;
            newobj.type = "checkbox";
            const newevt = {currentTarget: newobj, target: newobj, type: "checkbox"};
            this.props.onChange(newevt);
        }
    }

    render() {
        const {name,value,checked,defaultOptions=true,onChange, isValid, isInvalid,...props} = this.props;
        return <Form.Check custom type="checkbox" onChange={this.handleChange} isValid={isValid} isInvalid={isInvalid} id={name} checked={value} {...props}/>;
    }
}
 
export default CheckboxInput;
