import React, { Component } from 'react';

export class Error404 extends Component {
    render() { 
        return <h1>Pagina nao encontrada (404)</h1>;
    }
}

export class Error301 extends Component {
    render() { 
        return <h1>Acesso nao autorizado (301)</h1>;
    }
}