import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import auth from '../services/authentication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Nav, Collapse } from 'react-bootstrap';
import helpers from '../services/helpers';

const isActivePath = (path:string) => {
    return window.location.pathname===path;
}

const getItemClasses = (depth:number) => {
    return "nav-link "+(depth===0?"":"nav-link-sub ");
}

const getMenuList = ()=>{
    const session = auth.getSession();
    // Monta a hierarquia de menus
    if(!session || !session.data || !session.data.acesso) return;
    const all_itens = helpers.clone(session.data.acesso); //console.log(all_itens);
    let menu_itens = [];
    // Raiz
    for(let i=0;i<all_itens.length;i++){ //Mapeia os items root
        let item = all_itens[i];
        if(item.displayMenu==='1' && item.dependencia===null) menu_itens.push(item);
    }
    // Subnivel
    for(let i=0;i<all_itens.length;i++){ //Mapea os outros niveis
        let subitem = all_itens[i];
        for(let ii=0;ii<menu_itens.length;ii++){ //Mapea os outros niveis
            let item = menu_itens[ii];
            if(subitem.displayMenu==='1' && subitem.dependencia===item.id){
                if(!item.children){ item.children = []; item.opened = false; }
                if(!item.opened) item.opened = isActivePath(subitem.path);
                item.children.push(subitem);
            }
        }
    }
    return menu_itens;
}

class SideMenu extends Component<any,any> {
    _mounted:boolean=false;
    state:any = {
        accessList: [],
        activePath: null
    }

    submenuStyle = {
        backgroundColor: "#e0e0e0",
        padding: "5px 0px 5px 30px",
        listStyle: "none"
    }

    arrowStyle:React.CSSProperties = {
        display: "inline-block",
        marginTop:"7px",
        verticalAlign: "middle",
        fontSize: "11px",
        float:"right"
    }

    componentDidMount() {
        this._mounted = true;
        auth.addListener(this.updateElements);
        this.updateElements();
    }

    componentWillUnmount() {
        this._mounted = false;
        auth.removeListener(this.updateElements);
    }

    updateElements = () => {
        if(this._mounted) this.setState({accessList: getMenuList()});
    }

    toggleOpenItem = (item:any) => {
        let {accessList} = this.state;
        for(let i=0;i<accessList.length;i++){
            let it = accessList[i];
            if(it.id===item.id){
                it.opened = !it.opened;
                break;
            }
        }
        if(this._mounted) this.setState({accessList});
    }

    renderMenuItem = (item:any,depth=0) => {
        return <li className={"nav-item"} key={item.id}>
            <Link className={getItemClasses(depth)+(isActivePath(item.path)?"active":"")} to={item.path ? item.path : "#" } onClick={item.path ? undefined : (item.children && (()=>this.toggleOpenItem(item)))}>
                <FontAwesomeIcon className="mr-1" icon={(item.faIcon!=null?item.faIcon:"chevron-right")} style={{width:"20px"}}/>
                {item.label}
                {item.children && <span onClick={item.path ? (item.children && (()=>this.toggleOpenItem(item))):undefined}><FontAwesomeIcon icon="plus" transform={{rotate: item.opened?45:0}} style={this.arrowStyle} /></span>}
            </Link>
            {item.children &&
                <Collapse in={item.opened}>
                    <ul className="submenu m-0" style={this.submenuStyle}>
                        {item.children.map((sub:any) => this.renderMenuItem(sub,depth+1))}
                    </ul>
                </Collapse>
            }
        </li>
    }

    render() { 
        const {accessList} = this.state;
        return ( 
            <Col md={2} className="d-none d-md-block sidebar">
                <div className="sidebar-sticky">
                    <Nav className="flex-column">
                        {accessList.map((val:any) => this.renderMenuItem(val))}
                    </Nav>
                </div>
            </Col>
        );
    }
    
    // Atualiza a lista quando o Link ativo muda
    static getDerivedStateFromProps(nextProps:any, prevState:any):any {
        const session = auth.getSession();
        if(!session || !session.data || !session.data.acesso) return null;
        const currentActive = session.data.acesso.find((i:any)=>isActivePath(i.path));
        if(currentActive == prevState.activePath) return null;
        return ({accessList: getMenuList(), activePath: currentActive});
    }
}
 
export default withRouter(SideMenu);