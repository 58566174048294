import React, { Component } from 'react';
import authentication from '../services/authentication';

class Footer extends Component {
    state = {  }

    render() { 
        return ( 
            <footer className="page-footer font-small blue pt-4">
                <div className="footer-copyright text-center py-3 small"><a href="https://www.nodesistemas.com.br"> Node Sistemas</a> © 2019 | {authentication.getSessionToken()}</div>
            </footer>
        );
    }
}
 
export default Footer;