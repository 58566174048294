import React from 'react';
import Joi from 'joi';
import logo from '../../media/jerry.jpg';
import auth from '../../services/authentication';
import './login.css';

import SpinnerOverlay from '../common/spinnerOverlay';
import helpers from '../../services/helpers';
import LoginForm from './../common/loginForm';
import CheckboxInput from './../common/input/checkboxInput';

class Login extends LoginForm {
    state:any = {
        loading: false,
        redirTarget: "/",
        data:{
            username:'',
            password:'',
            remember: false,
        },
        errors:{}
    };

    schema = {
        username: Joi.string().required().label('Usuário'),
        password: Joi.string().required().label('Senha'),
        remember: Joi.optional()
    };

    async componentDidMount() {
        const {username,remember} = auth.getStoredLogin();
        const _state = {...this.state};
        _state.redirTarget = helpers.getDesiredPath();
        if(remember==='true'){
            _state.data.username = username ? username : '';
            _state.data.remember = remember==='true';
        }
        auth.loadSession();
        const has_token = auth.getSessionToken();
        _state.loading = Boolean(has_token);
        this.setState(_state);
        if(has_token){
            await auth.fetchSession();
            if(auth.sessionActive()) this.props.history.replace(_state.redirTarget);
        }
    }

    doSubmit = async () => {
        const {data} = this.state;
        const _state = {...this.state};
        _state.loading = true;
        this.setState(_state);
        auth.submitLogin(data.username,data.password, async (response) => {
            if(response.data.success){
                auth.storeSession(data.remember.toString());

                await auth.fetchSession();
                this.props.history.replace("/");
            } else {
                auth.forgetSession();

                const _state = {...this.state};
                let display_error = "";
                if(!response.data.error){
                    this.props.alertError(<>Erro: {response.data.toString()}</>);
                    return;
                }
                response.data.error.forEach((err:any) => {
                    display_error = err.desc+"\n";
                });
                display_error.trim();
                _state.loading=false;
                _state.errors.returned = display_error;
                this.setState(_state);
            }
        });
    }

    render() {
        return ( 
            <>
            <div className="text-center vertical-center">
                <form className="form-signin" onSubmit={this.handleSubmit}>
                <img className="mb-4" src={logo} alt="" width="72" height="72"/>
                <h1 className="h3 mb-3 font-weight-normal">SACE</h1>

                {this.state.errors.returned && <div className="alert alert-danger">{this.state.errors.returned}</div>}

                {this.renderInput({name:"username",type:"text",label:"Usuário", value: this.state.data.username, onChange: this.handleChange, autoFocus:true, error: this.state.errors.username, spellCheck:"false"})}
                {this.renderInput({name:"password",type:"password",label:"Senha", value: this.state.data.password, onChange: this.handleChange, error: this.state.errors.password, spellCheck:"false"})}

                <div className="checkbox mb-3">
                    <label>
                    <CheckboxInput name="remember" id="remember" label="Lembrar login" checked={this.state.data.remember} onChange={this.handleChange}/>
                    </label>
                </div>

                <button className="btn btn-lg btn-primary btn-block" type="submit" disabled={this.validate()}>Entrar</button>
                <p className="mt-5 mb-3 text-muted">Node Sistemas &copy; 2019</p>
                {/* <p className="mt-5 mb-3 text-muted">Redir: {this.state.redirTarget}</p> */}
                </form>
            </div>
            {this.state.loading ? <SpinnerOverlay/> : null }
            </>
         );
    }
}
 
export default Login;