import React from 'react';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SpinnerOverlayProps {
    variant?:'primary'| 'secondary'| 'success'| 'danger'| 'warning'| 'info'| 'light'| 'dark';
    status?:'success' | 'error';
    [props: string]: any;
}

const SpinnerOverlay = ({variant = "light",status,...props}: SpinnerOverlayProps) => {
    const maxSize = "3rem";
    let element;
    switch(status){
        case "success": element = <FontAwesomeIcon icon="check" className="text-success icon-pop" style={{width: maxSize,height: maxSize}} {...props}/>
        break;
        case "error": element = <FontAwesomeIcon icon="times" className="text-danger icon-pop" style={{width: maxSize,height: maxSize}} {...props}/>
        break;
        default: element = <Spinner animation="border" style={{width: maxSize,height: maxSize}} variant={variant} {...props}/>;
    }

    return <div className="fullscreen-spinner">
        {element}
    </div>;
}
 
export default SpinnerOverlay;