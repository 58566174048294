import axios from 'axios';
import helpers from './helpers';

var session:any = {}

var request_options:any = {
    withCredentials: true,
    headers: {}
}

var listeners:Array<()=>any> = [];

function dispatchSessionEvent(){
    for(let i=0;i<listeners.length;i++){
        if(listeners[i]) listeners[i]();
    }
}

export default {
    addListener: (func:()=>any) => {
        if(!func) return;
        if(listeners.includes(func)) return;
        listeners.push(func);
    },
    removeListener: (func:any)=>{
        let idx = listeners.indexOf(func);
        if(idx>-1) listeners.splice(idx,1);
    },
    fetchSession: async () => {
        const {data} = await axios.get(helpers.backendPath('login'), request_options);
        session.data = data;
        if(!session.data || !session.data.activated) sessionStorage.removeItem('login.token');
        //console.log('Saving session cache',{...session});
        sessionStorage.setItem('cachedsession',helpers.encode(JSON.stringify(session)));
        //console.log(session);
        dispatchSessionEvent();
    },
    getSession: () => { return session; },
    getSessionToken: () => { return session.token; },
    sessionActive: () => { return session.data ? session.data.activated===true : false; },
    checkPermission: (path:string) => {
        return session.data.acesso.find((a:any)=>a.path===path)!=null;
    },
    getAcessos: ()=>{
        return session.data.acesso;
    },
    getAuthOptions: () => {
        return request_options;
    },

    submitLogin: (username:string, password:string, onFinish:(response: any)=>any) => {
        const data = {action: "login", username,password};
        session = {};
        request_options.headers['Authorization'] = null;
        axios.post( helpers.backendPath('login'),data,request_options).then(resp=>{
            session.username = username;
            session.token = resp.data.token;
            request_options.headers['Authorization'] = 'Bearer '+session.token;
            onFinish(resp);
        }).catch(err=>onFinish(err.response));
    },
    logout: async () => {
        const data = {action: "logout"};
        axios.post( helpers.backendPath('login'),data,request_options).catch(err=>console.log(err));
        session = {};
        sessionStorage.removeItem('login.token');
        sessionStorage.removeItem('cachedsession');
        request_options.headers['Authorization'] = '';
        dispatchSessionEvent();
    },

    loadSessionCache: () => {
        const cachedSession = sessionStorage.getItem('cachedsession');
        if(cachedSession){
            session = JSON.parse(helpers.decode(cachedSession));
            if(session.token) request_options.headers['Authorization'] = 'Bearer '+session.token;
            //console.log('Loaded session cache',{...session});
        }
    },

    loadSession: () =>{
        session = {
            username: sessionStorage.getItem('login.username'),
            token: sessionStorage.getItem('login.token')
        };
        if(session.token) request_options.headers['Authorization'] = 'Bearer '+session.token;
    },
    storeSession: (remember:string) => {
        sessionStorage.setItem('login.username',session.username);
        sessionStorage.setItem('login.remember',remember);
        sessionStorage.setItem('login.token', session.token);
    },
    forgetSession: () => {
        sessionStorage.removeItem('login.username');
        sessionStorage.removeItem('login.remember');
        sessionStorage.removeItem('login.token');
    },
    getStoredLogin: () => {
        return {username: sessionStorage.getItem('login.username'), remember: sessionStorage.getItem('login.remember')};
    },
}