
export default class validation {
    static password(value:string){
        if(value.length<3) return false;
        return true;
    }
    static required(value:string){
        return value?value.length>0:false;
    }
    static cpf(value:string){
        const cpf = value.replace(/[^\d]+/g,'');
        if(cpf.length != 11) return false;
        // Se todos os characteres forem iguais, é um cpf invalido q passa na regra.
        let anydiff=false;
        for(let i=1;i<cpf.length;i++) if(cpf[i]!=cpf[0]) {anydiff = true; break;}
        if(!anydiff) return false;
        // Valida 1o digito
        let add = 0;
        for (let i=0; i < 9; i ++) add += parseInt(cpf.charAt(i)) * (10 - i);
        let rev = 11 - (add % 11);
        if (rev == 10 || rev == 11)	rev = 0;
        if (rev != parseInt(cpf.charAt(9))) return false;
        // Valida 2o digito
        add = 0;
        for(let i=0;i<10;i++) add += parseInt(cpf.charAt(i)) * (11 - i);
        rev = 11 - (add % 11);
        if (rev == 10 || rev == 11)	rev = 0;
        if (rev != parseInt(cpf.charAt(10))) return false;

        return true;
    }
}