import React from 'react';
import { FixedSizeList as List } from "react-window";
import { MenuListComponentProps } from 'react-select/lib/components/Menu';

class VirtualizedMenuList extends React.Component<MenuListComponentProps<any>,any> {
    render() {
        const height = 35;
        const { options, children, maxHeight, getValue }:any = this.props;
        const [value] = getValue();
        const initialOffset = options.indexOf(value) * height;
        const itemCount = children.length || 0;

        return (
        <List
            width={''}
            height={itemCount>10?maxHeight:itemCount*height}
            itemCount={itemCount}
            itemSize={height}
            initialScrollOffset={initialOffset||0}
        >
            {({ index, style }) => <div style={style}>{children[index]}</div>}
        </List>
        );
    }
}

export default VirtualizedMenuList;