import React from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';

//{label, name, type, data, placeholder, validation, joinNextField, props}
export interface NodeFormField {
    label?:string|null;
    name:string;
    type:any;
    defaultValue?:any;
    ignoreValue?:boolean;
    placeholder?:string;
    data?:any;
    validation?:(value:any,form: NodeForm)=>boolean;
    joinNextField?:boolean|null;
    labelClasses?:string;
    fieldClasses?:string;
    props?:any;
}

//{label, variant, action, isSubmit}
export interface NodeFormButton {
    label:string;
    variant?:'primary'| 'secondary'| 'success'| 'danger'| 'warning'| 'info'| 'dark'| 'light'| 'link'| 'outline-primary'| 'outline-secondary'| 'outline-success'| 'outline-danger'| 'outline-warning'| 'outline-info'| 'outline-dark'| 'outline-light';
    isSubmit?:boolean;
    action:(form:NodeForm)=>void;
}

export interface NodeFormData {
    fields:Array<NodeFormField>;
    buttons?:Array<NodeFormButton>;
    buttonClasses?:string;
    onChange?:(form: NodeForm)=>void;
    initialState?:any;
}

export interface NodeFormProps {
    formData:NodeFormData;
}

class NodeForm extends React.Component<NodeFormProps,any> {
    constructor(props:any){
        super(props);
        this.state = {fields: this.getDefaults(true), highlightInvalid: false};
    }
    _mounted=false;

    componentDidMount() {
        this._mounted=true;
    }
    componentWillUnmount() {
        this._mounted=false;
    }

    submitAction:any = null;

    getDefaults = (initial=false)=>{
        let fields:any = {};
        const {formData} = this.props;
        formData.fields.map((field:any)=>fields[field.name]={value: initial && formData.initialState && formData.initialState[field.name] ? formData.initialState[field.name] : field.defaultValue, valid: null, ignore: field.ignoreValue || false});
        return fields;
    }  

    handleChange = (evt:React.ChangeEvent<HTMLInputElement>)=>{
        let newState = {...this.state};
        const {validation, ignoreValue}:any = this.props.formData.fields.find((field:NodeFormField)=>field.name===evt.target.name);
        const isValid = validation?validation(evt.target.value,this):true;
        newState.fields[evt.target.name] = {value: evt.target.value, valid: isValid, ignore: ignoreValue || false};
        if(this._mounted) this.setState(newState);
        if(this.props.formData.onChange) this.props.formData.onChange(this);
    }

    // Render
    renderField = ({label, name, type, data, placeholder, validation, joinNextField, labelClasses, fieldClasses, props, defaultValue}:NodeFormField,idx:number,array:Array<any>, insideJoin?:boolean) => {
        let control;
        
        const isJoined = idx>0 ? array[idx-1].joinNextField : false;
        if(isJoined && !insideJoin) return;

        const isValid = validation && this.state.fields[name].valid;
        const isInvalid = this.state.highlightInvalid ? (isValid===false?true:null) : null;
        if(typeof(type)=="string"){
            switch(type){
                case "dropdown":
                    control = <Form.Control size="sm" as="select" name={name} value={this.state.fields[name].value} isValid={isValid} isInvalid={isInvalid} onChange={this.handleChange} {...props}>
                        {data.map((option:any,idx:number)=>
                            <option value={option.value} key={idx}>{option.label}</option>
                        )}
                    </Form.Control>;
                break;
                default:
                control = <Form.Control size="sm" name={name} value={this.state.fields[name].value} placeholder={placeholder} isValid={isValid} isInvalid={isInvalid} type={type} onChange={this.handleChange} {...props}/>
            }
        } else {
            let SubComponent = type;
            control = <SubComponent name={name} value={this.state.fields[name].value} defaultValue={defaultValue} onChange={this.handleChange} isValid={isValid} isInvalid={isInvalid} placeholder={placeholder} {...props}/>;
        }
        if(isJoined){
            return <>
                <Form.Label column className={"mr-2 mt-1 p-0 text-right small "+(labelClasses||"col-2")}>{label}</Form.Label>
                <Col className={"mr-3 p-0 "+fieldClasses}>
                    {control}
                </Col>
            </>;
        } else {
            return <Form.Group as={Row} className="m-0 mt-2 p-0" key={idx}>
                        <Form.Label column className={"mr-2 mt-1 p-0 text-right small "+(labelClasses||"col-2")}>{label}</Form.Label>
                        <Col className={"mr-3 p-0 "+fieldClasses}>
                            {control}
                        </Col>
                        {joinNextField && this.renderField(array[idx+1],idx+1,array,true)}
                        {/* {joinNextField===null && <div className="col col-6 mr-4 ml-3"></div>} */}
                    </Form.Group>;
        }
    }
    renderButton = ({label, variant, action, isSubmit}:NodeFormButton,idx:number) => {
        let clickAction:any = ()=>console.warn(`Acao nao definida no botao: ${label}`);
        if(action) {
            clickAction = action;
        }
        if(isSubmit) this.submitAction = ()=>clickAction(this);
        return <Button key={idx} className="m-2 mr-0" variant={variant} onClick={()=>clickAction(this)}>{label}</Button>
    }

    // Methods
    getValues = ()=>{
        const {fields} = this.state;
        let vals:any = {};
        for (let key in fields) {
            if(fields.hasOwnProperty(key) && !fields[key].ignore) vals[key] = fields[key].value;
        }
        return vals;
    }
    clear = async ()=>{
        if(this._mounted) await this.setState({fields: this.getDefaults()});
    }

    getFieldValue = (fieldName:any)=>{
        return this.state.fields[fieldName].value;
    }
    isFieldValid = (fieldName:any)=>{
        return this.state.fields[fieldName].valid;
    }

    validateAll = (checkOnly=false)=>{
        let {fields} = this.state;
        let allValid = true;
        for(let field of this.props.formData.fields){
            if(checkOnly){
                if(allValid && (field.validation && field.validation(fields[field.name].value,this))===false) allValid=false;
            } else {
                fields[field.name].valid = field.validation && field.validation(fields[field.name].value,this);
                if(allValid && fields[field.name].valid===false) allValid=false;
            }
        }
        return allValid;
    }

    highlightInvalid = ()=>{
        if(this._mounted) this.setState({highlightInvalid: true});
    }

    render() {
        const {formData} = this.props;
        return <Form className="mt-2" onSubmit={(e:React.FormEvent<HTMLFormElement>)=>{e.preventDefault(); if(this.submitAction)this.submitAction();}}>
            {formData.fields.map((field:any,idx:any,arr:Array<any>)=>this.renderField(field,idx,arr))}
            <Form.Group as={Row} className="m-0 my-2 p-0">
                <Col className={"p-0 "+(formData.buttonClasses||"offset-2")}>
                    {formData.buttons && formData.buttons.map((button:NodeFormButton,idx:number)=>this.renderButton(button,idx))}
                </Col>
            </Form.Group>
        </Form>;
    }
}
 
export default NodeForm;