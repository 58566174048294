import React from 'react';
import helpers from '../../../services/helpers';
import {components} from 'react-select';
import SelectAsync from 'react-select/lib/Async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {fas} from '@fortawesome/free-solid-svg-icons';
import VirtualizedMenuList from './../virtualizedMenuList';

const ICON_LIST = (()=>{
    let ret=[];
    for (let [key,value] of Object.entries(fas)) {
        ret.push({label: value.iconName, value: value.iconName});
    }
    return ret;
})();

const maxDisplayCount = 1000;

const CustomOption = (props:any) => {
    return <components.Option {...props}>
        <FontAwesomeIcon className="mr-2" icon={props.data.value}/> {props.data.label}
    </components.Option>;
}
const CustomSingleValue = ({ data, ...props }:any) => {
    return <components.SingleValue {...props}>
        <FontAwesomeIcon className="mr-2" icon={data.value}/> {data.label}
    </components.SingleValue>
};

class IconSelect extends React.Component<any,any> {
    _mounted=false;
    state:any = {
        data: undefined,
        inputValue: '',
    }
    
    componentDidMount() {this._mounted=true;}
    componentWillUnmount() {this._mounted=false;}

    handleChange = (data:any,action:any)=>{
        if(this.props.onChange){
            let newobj = {...this.props};
            newobj.value = data.value;
            this.props.onChange({target: newobj});
        } else {
            this.setState({data});
        }
    }
    handleInputChange = (newValue: string) => {
        this.setState({ newValue});
        return newValue;
    }

    filterList = (inputValue: string) => {
        let displayCount = 0;
        let opts = [];
        for(let i=0;i<ICON_LIST.length;i++){
            const icon = ICON_LIST[i];
            if(opts.length>maxDisplayCount)break;
            if(helpers.lazyCompare(icon.value,inputValue))opts.push(icon);
        }
        return opts;
      };

    getOptions = (inputVal:any,callback:any)=>{
        setTimeout(() => {
            callback(this.filterList(inputVal));
        }, 0);
    }

    setValue = (value:any)=>{
        const optionValue = ICON_LIST.find((opt:any)=>opt.value===value);
        return optionValue;
    }

    render() {
        const {value,defaultOptions=true,onChange, isValid, isInvalid,...props} = this.props;
        return <SelectAsync className="small" 
                isSearcheable
                defaultOptions
                loadOptions={this.getOptions}
                value={this.setValue(value)}
                components={{ Option: CustomOption, SingleValue: CustomSingleValue, MenuList: VirtualizedMenuList }}
                getOptionLabel={(option:any) => option}
                getOptionValue={(option:any) => option}
                onChange={this.handleChange}
                onInputChange={this.handleInputChange}
                {...props}
                {...helpers.getSelectionLocalization(isInvalid?false:(isValid?true:null))} />;
    }
}
 
export default IconSelect;