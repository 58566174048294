import React from 'react';
import helpers from '../../services/helpers';
import axios from 'axios';
import MUIDataTable from 'mui-datatables';

import { Card, Button, Badge, Modal, Container, Tab, Breadcrumb, Row, Col, Alert, Fade, ProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownIcon from '../common/dropdownIcon';
import NodeForm, { NodeFormData, NodeFormField } from '../common/nodeForm';
import validation from '../../services/validation';
import auth from '../../services/authentication';
import SpinnerOverlay from './../common/spinnerOverlay';
import IconSelect from './../common/input/iconSelect';
import AcessoInput from './../common/input/acessoInput';
import CheckboxInput from './../common/input/checkboxInput';

const options:any = {
    textLabels: helpers.getDatatableLocalization(),
    print:false,
    download:false,
    filter:true,
    search:true,
    viewColumns:false,
    elevation: 0,
    customToolbarSelect: ()=><></>,
};

const defaultFields:Array<NodeFormField> = [
    {label:"Descrição", name:"label", type: "text", placeholder: "", defaultValue: "", validation: validation.required, labelClasses:"col-1", joinNextField: true},
    {label:"Icone", name:"faIcon", type: IconSelect, placeholder: "", defaultValue: ""},
    {label:"Caminho", name:"path", type: "text", placeholder: "", defaultValue: "", labelClasses:"col-1"},
    {label:"Dependencia", name:"dependencia", type: AcessoInput, defaultValue: "", props:{extraOptions: [{label:"Nenhuma", value:""}]}, labelClasses:"col-1", fieldClasses:"col-2"},
    {label:null, name:"displayMenu", type: CheckboxInput, props:{label:"Mostrar no menu"}, defaultValue: false, labelClasses:"col-1"}
];

class Acesso extends React.Component<any,any> {
    _mounted=false;
    state:any = {
        editingAcesso: null,
        acessoModalOpened:false,

        lastRefresh: 0,
        acessoData:[],

        fetchingData: false,

        spinnerOverlay: false,
        spinnerStatus: null
    }

    // Formulario add/edit acesso
    acessoForm:NodeFormData = {
        fields: defaultFields,
        buttons: [
            {label: "Concluir", variant: "primary", isSubmit: true, action: (form: NodeForm)=>{
                const allValid = form.validateAll();
                if(allValid){
                    const values = form.getValues();
                    this.setAcesso(values);
                } else {
                    form.highlightInvalid();
                }
            }},
            {label: "Cancelar", variant: "secondary", action: (form: NodeForm)=>{
                this.closeAcessoModal();
            }}
        ],
        buttonClasses:"offset-1"
    }

    setAcesso = (values:any)=>{
        if(this._mounted) this.setState({spinnerOverlay: true, spinnerStatus: null});
        const data:any = {
            ...values,
            action: this.state.editingAcesso?'update':'insert',
            id: this.state.editingAcesso
        }
        axios.post(helpers.backendPath('acessoNivel/acessoNivel'),data,{...auth.getAuthOptions()}).then(response=>{
            if(this._mounted){
                this.setState({spinnerStatus: "success"});
                this.updateRemoteData(true);
                auth.fetchSession();
                setTimeout(()=>{
                    if(this._mounted) this.setState({spinnerOverlay: false});
                    this.closeAcessoModal();
                }, 1500);
            }
        }).catch(err=>{
            auth.fetchSession();
            if(this._mounted){
                this.setState({spinnerStatus: "error"});
                setTimeout(()=>{if(this._mounted){
                    this.setState({spinnerOverlay: false});
                    this.props.alertError(<>Erro {err.response.status}: {err.response.statusText}<br/><br/>{err.response.data}</>);
                }}, 1500);
            };
        });
    }

    updateRemoteData = (refresh?:boolean)=>{
        if(this._mounted) this.setState({fetchingData:true});
        helpers.backendGet('acessoNivel/acessoNivel',null,refresh?false:true).then((response)=>{
            if(this._mounted) this.setState({ 
                fetchingData: false,
                lastRefresh: Date.now(),
                //nivelData: response.data.niveis,
                //acessoNivelData: response.data.acessoNivel,
                acessoData: response.data.acesso
            });
        }).catch(err=>{
            auth.fetchSession();
            if(this._mounted){
                setTimeout(()=>{if(this._mounted){
                    this.setState({fetchingData: false});
                    if(err.response){
                        this.props.alertError(<>Erro {err.response.status}: {err.response.statusText}<br/><br/>{err.response.data}</>);
                    } else {
                        this.props.alertError(<>{err.toString()}</>);
                    }
                }}, 1500);
            };
        });
    }

    componentDidMount(){
        this._mounted=true;
        this.updateRemoteData(true);
    }
    componentWillUnmount() {this._mounted=false;}

    openAcessoModal = (editID?:any)=> {
        const acessoData = this.state.acessoData.find((acesso:any)=>acesso.id==editID);
        let fields = helpers.clone(defaultFields);
        this.acessoForm.fields = fields;
        for (let i = 0; i < this.acessoForm.fields.length; i++) {
            const key=this.acessoForm.fields[i].name;
            if(acessoData && acessoData.hasOwnProperty(key) && acessoData[key]!==null){
                if(key==="displayMenu") this.acessoForm.fields[i].defaultValue = acessoData[key]==="1"?true:false;
                else this.acessoForm.fields[i].defaultValue = acessoData[key];
            }
        }

        if(this._mounted) this.setState({editingAcesso: editID?editID:null,acessoModalOpened: true});
    }
    closeAcessoModal = ()=>{if(this._mounted)this.setState({acessoModalOpened: false})};

    deleteAcesso = (id:any)=>{
        if(this._mounted) this.setState({spinnerOverlay: true, spinnerStatus: null});
        
        const data:any = { id: id };
        axios.delete(helpers.backendPath('acessoNivel/acessoNivel'),{params: data,...auth.getAuthOptions()}).then(async response=>{
            if(this._mounted){
                this.setState({spinnerStatus: "success"});
                this.updateRemoteData(true);
                auth.fetchSession();
                setTimeout(()=>{if(this._mounted) this.setState({spinnerOverlay: false})}, 1500);
            }
        }).catch((err)=>{
            auth.fetchSession();
            if(this._mounted){
                this.setState({spinnerStatus: "error"});
                setTimeout(()=>{if(this._mounted){
                    this.setState({spinnerOverlay: false});
                    this.props.alertError(<>Erro {err.response.status}: {err.response.statusText}<br/><br/>{err.response.data}</>);
                }}, 1500);
            };
        });
    }

    render() {
        const acessoOptions:any = {
            ...options,
            selectableRows: false,
            customToolbar: ()=><>
                <Button className="ml-4 mt-2" variant="success" size={"sm"} style={{float:"right"}} onClick={()=>this.openAcessoModal()}>Nova Regra</Button>
            </>,
        };
        const blank={options:{empty:true,filter:false,sort:false,searchable:false,viewColumns:false,print:false,download:false}};

        const acessoActionOptions = [
            {label: "Editar", onClick:(ref:any)=>{
                this.openAcessoModal(this.state.acessoData[ref.rowIndex].id);
            }},
            {label: "Remover", onClick:(ref:any)=>{
                const item = this.state.acessoData[ref.rowIndex];
                let alertContent = {
                    title: `Remover acesso "${item.label}"`,
                    body: <p>Tem certeza que deseja remover a regra de acesso "{item.label}"?</p>,
                    buttons: [
                        {label: "REMOVER", variant: "danger", action: ()=>{this.deleteAcesso(item.id); this.props.closeAlertModal();}},
                        {label: "Cancelar", variant: "secondary", action: this.props.closeAlertModal},
                    ]
                }
                this.props.alertModal(alertContent);
            }}
        ];

        const acessoColumns:any = [
            {label: 'Codigo',name: 'id'},
            {label: 'Nome',name: 'label'},
            {label: 'Acesso',name: 'path'},
            {label: 'Icone',name: 'faIcon',options:{customBodyRender:(value:any)=>value && <FontAwesomeIcon icon={value} style={{width:"15px",height:"15px"}}></FontAwesomeIcon>}},
            {label: 'Item no Menu',name: 'displayMenu',options:{customBodyRender:(value:any)=><Badge variant={value==="1"?"primary":"secondary"} style={{fontSize:"small"}}>{value==="1"?"Sim":"Não"}</Badge>}},
            {label: 'Dependencia',name: 'dependencia',options:{customBodyRender:(value:any)=><Badge variant="light" style={{fontSize:"small"}}>{value && this.state.acessoData.find((acesso:any)=>acesso.id===value).label}</Badge>}},
            {label: 'Ações',options:{customBodyRender: (value:any,tableMeta:any)=><DropdownIcon id={tableMeta.rowIndex} refValue={tableMeta} items={acessoActionOptions}/>}}
        ];
        
        const editingData = this.state.acessoData.find((acesso:any)=>acesso.id===this.state.editingAcesso);

        return <>
            <Card className="mt-4" >
                <Fade in={this.state.fetchingData}><div>
                    <ProgressBar style={{height:"8px"}} animated now={100}/>
                </div></Fade>
                <MUIDataTable title={"Acesso"} columns={acessoColumns} data={this.state.acessoData} options={acessoOptions}/>
            </Card>

            <Modal centered show={this.state.acessoModalOpened} hidden={this.props.alertModalOpened} dialogClassName={"fullscreen-modal"} onHide={this.closeAcessoModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.state.editingAcesso?`Editando acesso: ${editingData && editingData.label}`:"Novo Acesso"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                    <NodeForm formData={this.acessoForm}/>
                    </Container>
                </Modal.Body>
            </Modal>

            {this.state.spinnerOverlay ? <SpinnerOverlay status={this.state.spinnerStatus}/> : null }
        </>
    }
}
 
export default Acesso;