import React from 'react';

import helpers from '../../services/helpers';
import axios from 'axios';

import MUIDataTable from 'mui-datatables';
import ReactJson from 'react-json-view';

import { Card, Badge, Fade, ProgressBar, Modal, Button, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NodeForm, { NodeFormData } from '../common/nodeForm';
import auth from '../../services/authentication';
import SpinnerOverlay from '../common/spinnerOverlay';
import DateButton from '../common/input/dateButton';
import { format } from 'date-fns';
import SearchInput from '../common/input/searchInput';

const options:any = {
    textLabels: helpers.getDatatableLocalization(),
    print:false,
    download:false,
    filter:true,
    search:true,
    viewColumns:false,
    elevation: 0,
    customToolbarSelect: ()=><></>,
};

class EventoSessao extends React.Component<any,any> {
    _mounted=false;
    state:any = {
        eventList:[],
        fetchingData: false,

        startDate: 0,
        endDate: new Date().getTime(),

        detailModal: false,

        spinnerOverlay: false,
        spinnerStatus: null
    }

    componentDidMount(){
        this._mounted=true;
        const redirectState = this.props.history.location.state;
        this.fetchData(redirectState);
    }
    componentWillUnmount() {this._mounted=false;}

    fetchData = (filters?:any) => {
        if(this._mounted) this.setState({fetchingData: true});
        axios.get(helpers.backendPath("sessao/eventoSessao"),{params: filters,...auth.getAuthOptions()}).then(response=>{
            if(this._mounted) this.setState({eventList: response.data.data, fetchingData: false});
        }).catch(err=>{
            auth.fetchSession();
            if(this._mounted){
                this.setState({fetchingData: false,spinnerStatus: "error"});
                setTimeout(()=>{if(this._mounted){
                    this.setState({spinnerOverlay: false});
                    this.props.alertError(<>Erro {err.response.status}: {err.response.statusText}<br/><br/>{err.response.data}</>);
                }}, 1500);
            };
        });
    }

    closeSession = (session:any, closeUser:boolean)=>{
        console.log(session);
        if(this._mounted) this.setState({spinnerOverlay: true, spinnerStatus: null});
        const data:any = {
            action: closeUser ? "close_user" : "close_session",
            id: closeUser ? session.usuario_id : session.sessao_id
        }
        console.log(data);
        axios.post(helpers.backendPath("sessao/sessao"),data, {...auth.getAuthOptions()}).then(response=>{
            console.log(response.data);
            if(this._mounted){
                this.setState({spinnerStatus: "success"});
                this.fetchData();
                auth.fetchSession();
                setTimeout(()=>{
                    if(this._mounted){
                        this.setState({spinnerOverlay: false});
                    }
                }, 1000);
            };
        }).catch(err=>{
            auth.fetchSession();
            if(this._mounted){
                this.setState({spinnerStatus: "error"});
                setTimeout(()=>{if(this._mounted){
                    this.setState({spinnerOverlay: false});
                    this.props.alertError(<>Erro {err.response.status}: {err.response.statusText}<br/><br/>{err.response.data}</>);
                }}, 1500);
            };
        });
    }

    showDetail = (event:any)=>{
        const jsonObj = JSON.parse(event.eventoSessao_evento);
        let alertContent = {
            title: `Detalhes do evento "${event.eventoSessao_descricao}"`,
            body: <ReactJson src={jsonObj} displayDataTypes={false} name={false}/>,
            buttons: [
                {label: "Fechar", variant: "secondary", action: this.props.closeAlertModal},
            ]
        }
        this.props.alertModal(alertContent);
    }

    render() {
        const blank={options:{empty:true,filter:false,sort:false,searchable:false,viewColumns:false,print:false,download:false}};

        const dataTableColumns:any = [
            {label: 'Sessão',name: 'sessao_id'},
            {label: 'Usuário',name: 'usuario_nome'},
            {label: 'Descrição',name: 'eventoSessao_descricao'},
            {label: 'Data',name: 'eventoSessao_timestamp',options:{customBodyRender:(value:any)=>{
                const [hora,data] = format(new Date(value*1000),'HH:mm|dd/MM/yy').split('|');
                return <>
                    <Badge style={{fontSize:"small"}}><FontAwesomeIcon className="mx-1" icon="clock" color="lightgray" />{hora}</Badge>
                    <Badge style={{fontSize:"small"}}><FontAwesomeIcon className="mx-1" icon="calendar-alt" color="lightgray" />{data}</Badge>
                </>;
            }}},
            {label: 'Detalhes', name: 'eventoSessao_evento', options:{
                customBodyRender:(value:any,meta:any) => <Button variant="dark" size="sm" onClick={()=>this.showDetail(this.state.eventList[meta.rowIndex])}>Detalhes</Button>
            }}
        ];
        const tableOptions:any = {
            ...options,
            selectableRows: false
        };
        
        const dateFieldProps = {
            showMonthDropdown:true,
            showYearDropdown:true,
            dropdownMode:"select",
            showTimeSelect: true,
            timeFormat:"HH:mm",
            timeIntervals:15,
            dateFormat:"dd/MM/yy HH:mm",
            valueFormat:"timestamp",
            timeCaption:"Hora",
            minDate: 0,
            maxDate: Math.min(new Date().getTime()),
        }

        // Form Pesquisa
        const filterForm:NodeFormData = {
            fields:[
                //{label:"Usuário", name:"usuario", type: "text", defaultValue: "", fieldClasses:"col-6"},
                {label:"Usuário", name:"usuario", type: SearchInput, props:{uri:'usuario/usuario',dataPath:'data',valuePath:'nome',placeholder: ""}, defaultValue: "", fieldClasses:"col-6"},
                {label:"Código Sessão", name:"sessao", type: "number", defaultValue:"", fieldClasses:"col-1"},
                //{label:"Descrição", name:"descricao", type: "text", defaultValue: "", fieldClasses:"col-6"},
                {label:"Descrição", name:"descricao", type: SearchInput, props:{uri:'sessao/eventoSessao?get_descriptions',dataPath:'data',valuePath:'eventoSessao_descricao',placeholder: ""}, defaultValue: "", fieldClasses:"col-6"},
                {label:"A partir de", name:"inicio", type: DateButton, props: {...dateFieldProps, selectsStart:true, startDate:this.state.startDate, endDate:this.state.endDate}, defaultValue: null, placeholder: "Qualquer data"},
                {label:"Até", name:"fim", type: DateButton, props: {...dateFieldProps, selectsEnd:true, startDate:this.state.startDate, endDate:this.state.endDate, minDate: this.state.startDate}, defaultValue: null, placeholder: "Agora"},
            ],
            buttons: [
                {label: "Atualizar", variant: "primary", isSubmit: true, action: (form: NodeForm)=>{
                    const filters = form.getValues();
                    filters.inicio = Math.round(filters.inicio/1000);
                    filters.fim = Math.round(filters.fim/1000);
                    this.fetchData(filters);
                }},
                {label: "Limpar", variant: "secondary", action: async (form: NodeForm)=>{
                    await form.clear();
                    const filters = form.getValues();
                    this.fetchData(filters);
                }}
            ],
            onChange: form=>{
                this.setState({startDate: form.getFieldValue('inicio'),endDate: form.getFieldValue('fim')});
            },
            initialState: this.props.history.location.state
        };

        return <>
            <Card className="mt-4" >
                <h5 className="m-2 mx-4">Filtrar Atividades</h5>
                <NodeForm formData={filterForm}/>
                <hr className="m-0"/>
                <Fade in={this.state.fetchingData}><div>
                    <ProgressBar style={{height:"8px"}} animated now={100}/>
                </div></Fade>
                <MUIDataTable title={"Atividades de Sessões"} columns={dataTableColumns} data={this.state.eventList} options={tableOptions}/>
            </Card>

            {this.state.spinnerOverlay ? <SpinnerOverlay status={this.state.spinnerStatus}/> : null }
        </>
    }
}
 
export default EventoSessao;