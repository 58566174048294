import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
 
class DropdownIcon extends React.Component<any, any> {
    state:any = {opened:false};

    render(){
        return <Dropdown className="no-caret" show={this.state.opened} drop="left" onToggle={(opened:any)=>this.setState({opened: opened})}>
            <Dropdown.Toggle as={IconButton as any} onClick={()=>this.setState({opened: !this.state.opened})} id={`dropdown-${this.props.id}`}>
                <FontAwesomeIcon icon="ellipsis-v" style={{width:"15px",height:"15px"}}/>
            </Dropdown.Toggle>
        
            <Dropdown.Menu>
                {this.props.items && this.props.items.map((item:any, key:number)=><Dropdown.Item key={key} onClick={()=>item.onClick(this.props.refValue)}>{item.label}</Dropdown.Item>)}
            </Dropdown.Menu>
        </Dropdown>
    }
}
 
export default DropdownIcon;