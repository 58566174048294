import React from 'react';
import { Modal, Button } from 'react-bootstrap';

export interface AlertModalProps {
    opened:boolean;
    onClose:()=>any;
    content:AlertModalContent;
}
export interface AlertModalContent {
    title?:string;
    body?:any;
    buttons?:Array<AlertModalButton>;
}
export interface AlertModalButton {
    label:string;
    variant?:|'primary'|'secondary'|'success'|'danger'|'warning'|'info'|'dark'|'light'|'link'|'outline-primary'|'outline-secondary'|'outline-success'|'outline-danger'|'outline-warning'|'outline-info'|'outline-dark'|'outline-light';
    action:()=>any;
}
 
class AlertModal extends React.Component<AlertModalProps, any> {
    render() { 
        const {opened,content,onClose} = this.props;
        return (
        <Modal centered show={opened} onHide={onClose}>
            {content.title && <Modal.Header closeButton>
                <Modal.Title>{content.title}</Modal.Title>
            </Modal.Header>}
            <Modal.Body>{content.body}</Modal.Body>
            {content.buttons && <Modal.Footer>
                {content.buttons.map((bt:AlertModalButton)=><Button key={bt.label} variant={bt.variant} onClick={bt.action}>{bt.label}</Button>)}
            </Modal.Footer>}
        </Modal>
        );
    }
}
 
export default AlertModal;