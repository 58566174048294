import React from 'react';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { Form, InputGroup } from 'react-bootstrap';

class CustomPickerInput extends React.Component<any,any> {
    render () {
        return (
          <InputGroup><Form.Control
            type="text"
            size="sm"
            value={this.props.value}
            placeholder={this.props.placeholder}
            onChange={this.props.onChange}
            isValid={this.props.isValid}
            isInvalid={this.props.isInvalid}
            onClick={this.props.onClick}/>
            
            <InputGroup.Append>
                <InputGroup.Text><FontAwesomeIcon icon="calendar-alt" /></InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        );
    }
}

class DateButton extends React.Component<any,any> {
    constructor(props:any){
        super(props);
        this.state = {
            value: this.props.defaultValue
        }
    }

    formatValue = (date:Date,scheme:string)=>{
        switch(scheme){
            case "date": return date;
            case "timestamp": return date.getTime();
            default: return format(date,scheme).toString();
        }
    }

    handleChange = (date:any)=>{
        const scheme = this.props.valueFormat || this.props.dateFormat;
        const formattedDate = date ? this.formatValue(date,scheme) : "";
        if(this.props.onChange){
            let newobj = {...this.props};
            newobj.value = formattedDate;
            newobj.type = "date";
            const newevt = {currentTarget: newobj, target: newobj, type: "date"};
            this.props.onChange(newevt);
        }
        this.setState({value: date});
    }

    render() { 
        const {value,onChange,defaultValue,dateFormat,isValid,isInvalid,placeholder,...others} = this.props;
        return <DatePicker 
                customInput={<CustomPickerInput isValid={isValid} isInvalid={isInvalid}/>}
                disabledKeyboardNavigation
                dateFormat={dateFormat}
                useWeekdaysShort
                selected={this.state.value}
                todayButton={"Hoje"}
                placeholderText={placeholder}
                onChange={this.handleChange}
                {...others}
            />;
    }
}
 
export default DateButton;