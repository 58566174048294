import React from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Navbar, Image, Badge, Form, Dropdown, Card } from 'react-bootstrap';
import { Tooltip, IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SelectAsync from 'react-select/lib/Async';
import { formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import logo from '../media/jerry.jpg';
import auth from '../services/authentication';
import helpers from '../services/helpers';


const IndicatorsContainer = (props:any) => {
    return (
        <div>
            <FontAwesomeIcon className="mr-2 mt-1" icon="search" color={"gray"} style={{width:"20px",height:"20px"}}/>
        </div>
    );
};

class SearchBar extends React.Component<any,any> {
    getOptions = (inputValue:string, callback:any)=>{
        const acessos = auth.getAcessos();
        let list:Array<any> = [];

        const getFullName = (item:any):string=>{
            if(item){
                if(item.dependencia===null) return item.label;
                else {
                    const dep = acessos.find((a:any)=>a.id===item.dependencia);
                    const depName = getFullName(dep);
                    return depName + " > " + item.label;
                }
            }
            return "Inválido";
        };

        for(let i=0;i<acessos.length;i++){
            const item = acessos[i];
            if(item.displayMenu==='1' && item.path!==null && auth.checkPermission(item.path)){
                const opt = {label: getFullName(item), value: item.path};
                list.push(opt);
            }
        }

        list = list.filter((item:any)=>helpers.lazyCompare(item.label,inputValue));
        callback(list);
    }

    handleChange = (item:any)=>{
        this.props.onSelect(item.value);
    }

    render(){
        const {...others} = this.props;
        return <SelectAsync
                loadOptions={this.getOptions}
                defaultOptions
                value={null}
                components={{ IndicatorsContainer }}
                placeholder="Pesquisar"
                onChange={this.handleChange}
                noOptionsMessage={(inputValue:any)=>"Nenhum item"}
                {...others}/>;
    }
}

class TopBar extends React.Component<any,any> {
    state = { 
        showNotifications: undefined
    }

    profileStyle = {
        width: "30px",
        height: "30px",
    }

    toggleNotifications = (evt:any)=>{
        if(!this.state.showNotifications){ //Se for visualizar agora, marca como lido
            //this.props.notifications.forEach((n:any) => n.viewed=true);
        }
        this.setState({showNotifications: !this.state.showNotifications});
    }

    jumpTo = (path:string)=>{
        this.props.history.push(path);
    }

    render() { 
        const notification_notViewed = this.props.notifications.filter((n:any)=>!n.visualisado).length;
        return ( 

            <Navbar className="p-0" collapseOnSelect expand="sm" variant="dark" bg="dark" fixed="top">
                <Navbar.Brand as={Link} className="col-sm-3 col-md-2 mr-0" to="/">
                    <Image className="d-inline-block align-top" src={logo} roundedCircle style={this.profileStyle} /> {' SACI'}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="px-3">
                    <Form className="pl-0 mr-auto col-6" inline>
                        <SearchBar className="col p-0 m-0" onSelect={this.jumpTo}/>
                    </Form>

                    <Tooltip title={"Notificações"}>
                        <div style={{position:"relative"}}>
                        <IconButton className="text-muted" onClick={this.toggleNotifications}>
                            <FontAwesomeIcon icon="bell" style={{width:"20px",height:"20px"}}/>
                        </IconButton>
                        <Badge className="badge-notify" pill variant="danger">{notification_notViewed > 0 && notification_notViewed}</Badge>
                        </div>
                    </Tooltip>
                    <Dropdown className="notify-dropdown" show={this.state.showNotifications} onToggle={(isOpen:boolean)=>this.setState({showNotifications: isOpen})} drop="down" alignRight>
                        <Dropdown.Menu>
                            {this.props.notifications.length > 0 ? this.props.notifications.map((notif:any, index:any)=>
                                <Card className="mb-1 shadow" key={index}>
                                    <Card.Header className="d-flex w-100 justify-content-between card-header p-2 m-0 rounded-top">
                                        <h5 className="mb-1">{notif.titulo}</h5>
                                        <small>{formatDistance(new Date(notif.timestamp),new Date,{ locale: ptBR })}</small>
                                    </Card.Header>
                                    <Card.Body className="px-2">
                                        {helpers.string2jsx(notif.conteudo)}
                                    </Card.Body>
                                    <Card.Footer className="p-1 m-0">
                                        {notif.visualisado ? 
                                            <small className="float-left">Lido a {formatDistance(new Date(notif.visualisado),new Date,{ locale: ptBR })}</small>
                                            :<small className="float-left"><a className="text-link" href="#" onClick={()=>this.props.onReadNotification(notif)}>Marcar como lido</a></small>
                                        }
                                        <small className="float-right"><a className="text-secondary" href="#" onClick={()=>this.props.onClearNotification(notif)}>Limpar</a></small>
                                    </Card.Footer>
                                </Card>
                            ) :
                                <Card className="mb-1">
                                    <Card.Footer className="d-flex w-100 justify-content-between card-header p-2 m-0 rounded-top">
                                        <h6 className="mb-1 m-2" style={{width:"300px"}}>{"Nenhuma notificação."}</h6>
                                    </Card.Footer>
                                </Card>
                            }
                        </Dropdown.Menu>
                    </Dropdown>

                    <Navbar.Text className="mx-3 p-0" style={{lineHeight:"1"}}>
                        <div><span className="text-white">Usuario:</span> {auth.getSession().username}</div>
                        <Badge variant="secondary" style={{fontSize:"xx-small",width:"100%"}}>{auth.getSession().data.nivel.label}</Badge>
                    </Navbar.Text>

                    <Tooltip title={"Sair"}>
                        <IconButton className="text-muted" onClick={(evt:any) => this.props.onClickLogout(evt)}>
                            <FontAwesomeIcon icon="sign-out-alt" style={{width:"20px",height:"20px"}}/>
                        </IconButton>
                    </Tooltip>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}
 
export default withRouter(TopBar);