import React from 'react';
import { Spinner, Alert, Fade, Badge, Card, Modal, Container, ProgressBar } from 'react-bootstrap';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import axios from 'axios';
import helpers from '../../services/helpers';
import auth from '../../services/authentication';
import validation from '../../services/validation';

import NodeForm, { NodeFormData } from '../common/nodeForm';
import NivelInput from '../common/input/nivelInput';
import ProtectedButton from '../common/protectedButton';
import SpinnerOverlay from '../common/spinnerOverlay';
import DropdownIcon from './../common/dropdownIcon';
import CPFInput from '../common/input/cpfInput';
import DateButton from './../common/input/dateButton';
import SearchInput from '../common/input/searchInput';

class Usuario extends React.Component<any,any> {
    _mounted:boolean=false;
    state:any = {
        loading: true,
        userList: [],
        nivelList: [],
        editingUser: null,
        userModal: false,
        spinnerOverlay: false,
        spinnerStatus: null,
    }

    // Formulario add/edit usuario
    userForm:NodeFormData = {
        fields:[
            {label:"Nome", name:"nome", type: "text", placeholder: "Ex: Joao", defaultValue: "", validation: validation.required, joinNextField: true, labelClasses:"col-1"},
            //{label:"CPF", name:"cpf", type: CPFInput, props:{size:"sm"}, defaultValue: "", validation: validation.cpf},
            //{label:"Nascimento", name:"nascimento", type: DateButton, props:{dateFormat: "dd/MM/yyyy", showMonthDropdown:true, showYearDropdown:true, dropdownMode:"select"}, defaultValue: "", placeholder: "Selecione...", validation: validation.required, labelClasses:"col-1", joinNextField: true},
            {label:"Nivel", name:"nivel", type: NivelInput, defaultValue:"", props:{}, validation: validation.required},
            {label:"Senha", name:"senha", type: "password", placeholder: "Senha", defaultValue: "", validation: (value:any, form:NodeForm)=>(!this.state.editingUser ? validation.password(value) : true), joinNextField: true, labelClasses:"col-1"},
            {label:"Confirmar Senha", name:"confirma_senha", type: "password", placeholder: "Confirma Senha", ignoreValue: true, defaultValue: "", validation: (value:any, form: NodeForm)=>(value===form.getFieldValue("senha") && form.isFieldValid("senha"))},
        ],
        buttons: [
            {label: "Concluir", variant: "primary", isSubmit: true, action: (form: NodeForm)=>{
                const allValid = form.validateAll();
                if(allValid){
                    const values = form.getValues();
                    console.log(values);
                    this.setUser(values);
                } else {
                    form.highlightInvalid();
                }
            }},
            {label: "Cancelar", variant: "secondary", action: (form: NodeForm)=>{
                this.closeUserModal();
            }}
        ],
        buttonClasses:"offset-1"
    }

    componentDidMount(){this._mounted=true; this.fetchData(); }
    componentWillUnmount() {this._mounted=false;}

    fetchData = (filters?:any) => {
        if(this._mounted) this.setState({loading: true});
        helpers.backendGet("nivel/nivel").then(response=>{
            if(this._mounted) this.setState({nivelList: response.data.niveis});
        });
        axios.get(helpers.backendPath("usuario/usuario"),{params: filters,...auth.getAuthOptions()}).then(response=>{
            let new_state = {...this.state};
            new_state.userList = response.data.data;
            new_state.loading=false;
            if(this._mounted) this.setState(new_state);
        }).catch(err=>{
            auth.fetchSession();
            if(this._mounted){
                this.setState({loading: false,spinnerStatus: "error"});
                setTimeout(()=>{if(this._mounted){
                    this.setState({spinnerOverlay: false});
                    this.props.alertError(<>Erro {err.response.status}: {err.response.statusText}<br/><br/>{err.response.data}</>);
                }}, 1500);
            };
        });
    }

    setUser = (values:any)=>{
        if(this._mounted) this.setState({spinnerOverlay: true, spinnerStatus: null});
        const data:any = {
            action: this.state.editingUser?"update":"insert",
            id: this.state.editingUser,
            ...values
        }
        axios.post(helpers.backendPath("usuario/usuario"),data, {...auth.getAuthOptions()}).then(response=>{
            console.log(response.data);
            if(this._mounted){
                this.setState({spinnerStatus: "success"});
                this.fetchData();
                auth.fetchSession();
                setTimeout(()=>{
                    if(this._mounted){
                        this.setState({spinnerOverlay: false});
                        this.closeUserModal();
                    }
                }, 1500);
            };
        }).catch(err=>{
            auth.fetchSession();
            if(this._mounted){
                this.setState({spinnerStatus: "error"});
                setTimeout(()=>{if(this._mounted){
                    this.setState({spinnerOverlay: false});
                    this.props.alertError(<>Erro {err.response.status}: {err.response.statusText}<br/><br/>{err.response.data}</>);
                }}, 1500);
            };
        });
    }

    openUserModal = (editID?:any)=>{
        const userData = this.state.userList.find((user:any)=>user.id==editID);
        for (let i = 0; i < this.userForm.fields.length; i++) {
            const key=this.userForm.fields[i].name;
            if(userData && userData.hasOwnProperty(key)){
                this.userForm.fields[i].defaultValue = userData[key];
            } else {
                this.userForm.fields[i].defaultValue="";
            }
        }

        //this.userForm.fields.find()

        if(this._mounted) this.setState({userModal: true, editingUser: editID?editID:null});
    }

    closeUserModal = ()=>{
        if(this._mounted) this.setState({userModal: false});
    }

    deleteUser = (id:any)=>{
        if(this._mounted) this.setState({spinnerOverlay: true, spinnerStatus: null});
        
        const data:any = { id: id };
        axios.delete(helpers.backendPath('usuario/usuario'),{params: data,...auth.getAuthOptions()}).then(async response=>{
            if(this._mounted){
                this.setState({spinnerStatus: "success"});
                this.fetchData();
                auth.fetchSession();
                setTimeout(()=>{if(this._mounted) this.setState({spinnerOverlay: false})}, 1500);
            }
        }).catch((err)=>{
            auth.fetchSession();
            if(this._mounted){
                this.setState({spinnerStatus: "error"});
                setTimeout(()=>{if(this._mounted){
                    this.setState({spinnerOverlay: false});
                    this.props.alertError(<>Erro {err.response.status}: {err.response.statusText}<br/><br/>{err.response.data}</>);
                }}, 1500);
            };
        });
    }

    render() {
        // Form Pesquisa
        const filterForm:NodeFormData = {
            fields:[
                {label:"Nome", name:"nome", type: SearchInput, props:{uri:'usuario/usuario',dataPath:'data',valuePath:'nome',placeholder: ""}, defaultValue: "", labelClasses:"col-1", fieldClasses:"col-6"},
                {label:"Nivel", name:"nivel", type: NivelInput, props:{extraOptions:[{label: "Todos",value:""}]}, defaultValue:"", labelClasses:"col-1", fieldClasses:"col-2"},
            ],
            buttons: [
                {label: "Atualizar", variant: "primary", isSubmit: true, action: (form: NodeForm)=>{
                    const filters = form.getValues();
                    this.fetchData(filters);
                }},
                {label: "Limpar", variant: "secondary", action: async (form: NodeForm)=>{
                    await form.clear();
                    const filters = form.getValues();
                    this.fetchData(filters);
                }}
            ],
            buttonClasses:"offset-1",
            initialState: this.props.history.location.state
        };

        // Datatable
        const userActionOptions = [
            {label: "Editar", onClick:(ref:any)=>{
                const item = this.state.userList[ref.rowIndex];
                this.openUserModal(item.id);
            }},
            {label: "Remover", onClick:(ref:any)=>{
                const item = this.state.userList[ref.rowIndex];
                let alertContent = {
                    title: `Remover Usuário "${item.nome}"`,
                    body: <p>Tem certeza que deseja remover o usuário "{item.nome}"?</p>,
                    buttons: [
                        {label: "REMOVER", variant: "danger", action: ()=>{this.deleteUser(item.id); this.props.closeAlertModal();}},
                        {label: "Cancelar", variant: "secondary", action: this.props.closeAlertModal},
                    ]
                }
                this.props.alertModal(alertContent);
            }}
        ];
        const blank={options:{empty:true,filter:false,sort:false,searchable:false,viewColumns:false,print:false,download:false}};
        const columns:any = [
            {label: 'Codigo',name: 'id'},
            {label: 'Nome',name: 'nome'},
            blank,blank,blank,
            {label: 'Nivel',name: 'nivel',options:{
                customBodyRender: (value:any,tableMeta:any)=>{
                    const nivel = this.state.nivelList.find((nivel:any)=>nivel.id===value);
                    return nivel?nivel.nome:<span className="text-danger">...</span>
                }
            }},blank,
            (auth.checkPermission('/usuario/cadastro') ? {label: 'Ação',options:{
                customBodyRender: (value:any,tableMeta:any)=><DropdownIcon id={tableMeta.rowIndex} refValue={tableMeta} items={userActionOptions}/>
            }}:blank)
        ];
        const data = this.state.userList;
        const userTableOptions:any = {
            textLabels: helpers.getDatatableLocalization(),
            selectableRows: false,
            elevation: 0,
        };

        return <>
            <div style={{marginTop:"18px"}}/>

            <div>
                <ProtectedButton accessPath="/usuario/cadastro" variant="success" style={{float:"right"}} onClick={()=>this.openUserModal()}>Adicionar Usuário</ProtectedButton>
                <h3>Usuários</h3>
            </div>

            {/* Filtro da listagem */}
            <Card className="mt-3">
                <h5 className="m-2 text-dark">Filtrar Usuários</h5>
                <NodeForm formData={filterForm}/>
                <hr className="m-0"/>
                {/* Listagem */}
                <Fade in={this.state.loading}><div>
                    <ProgressBar style={{height:"8px"}} animated now={100}/>
                </div></Fade>
                <MUIDataTable title="Usuários" columns={columns} data={data} options={userTableOptions}/>
            </Card>

            {/* Modal de novo usuario */}
            <Modal centered dialogClassName="fullscreen-modal" hidden={this.props.alertModalOpened} show={this.state.userModal} onHide={this.closeUserModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.state.editingUser ? `Editando usuário "${this.state.userList.find((user:any)=>user.id==this.state.editingUser).nome}"` : "Novo Usuário"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container style={{maxWidth: "none"}}>
                        <NodeForm formData={this.userForm}/>
                        {this.state.editingUser && <small className="text-secondary" style={{position:"absolute",bottom:"1em",right:"1em"}}>* Deixe os campos de senha em branco caso nao queira alterálos</small>}
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Loading */}
            {this.state.spinnerOverlay ? <SpinnerOverlay status={this.state.spinnerStatus}/> : null }
        </>
    }
}
 
export default Usuario;