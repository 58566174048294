import React, { FormEvent } from 'react';
import Joi from 'joi';

class LoginForm extends React.Component<any, any> {
    schema:any = null;
    doSubmit(){}

    state = { 
        data: {},
        errors: {}
    }

    validate = () => {
        const {error} = Joi.validate(this.state.data, this.schema, {abortEarly: false});
        if(!error)return null;
        const errors:any = {};
        for(let er of error.details) errors[er.path[0]] = er.message;
        return errors;
    }

    validateProperty = (input:HTMLInputElement) => {
        const obj = {[input.name]: input.value};
        const schema = {[input.name]: this.schema[input.name]}
        const {error} = Joi.validate(obj, schema);
        return error ? error.details[0].message : null;
    }

    handleSubmit = (evt:FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        
        const errors = this.validate();
        this.setState({errors: errors || {}});
        if(errors) return;

        this.doSubmit();
    }

    handleChange = (evt:React.ChangeEvent<HTMLInputElement>) =>{
        const {currentTarget: input} = evt;
        const _state:any = {...this.state};
        switch(input.type){
            case "checkbox": _state.data[input.name] = input.checked;
            break;
            default: 
                _state.data[input.name] = input.value;
                const errorMessage = this.validateProperty(input);
                if(errorMessage) _state.errors[input.name] = errorMessage;
                else delete _state.errors[input.name];
        }
        this.setState(_state);
    }

    renderInput(props:any) {
        return (
            <>
                <input id={props.name} className="form-control" placeholder={props.label} {...props} />
                {props.error && <div className="alert alert-danger">{props.error}</div>}
            </>
        );
    }
}
 
export default LoginForm;