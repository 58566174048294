import React from 'react';
import Sound from 'react-sound';

// Routing
import { Route, Switch, Redirect, withRouter, RouteComponentProps } from 'react-router-dom';

//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Alert } from 'react-bootstrap';

// Date Picker Config | Detalhes em: https://reactdatepicker.com/
import "react-datepicker/dist/react-datepicker.css";
import ptBR from 'date-fns/locale/pt-BR'
import { registerLocale, setDefaultLocale } from 'react-datepicker';

// NODE classes
import auth from './services/authentication';
import helpers from './services/helpers';
import axios from 'axios';

// Global Style
import './App.css';

// Assets
import notificationSound from './media/notification.mp3';

// Componentes de layout
import ProtectedRoute from './components/common/protectedRoute';
import SideMenu from './components/sidemenu';
import TopBar from './components/topBar';
import Footer from './components/footer';
import Login from './components/pages/login';
import * as ErrorPages from './components/errorPages';
import AlertModal, { AlertModalContent } from './components/common/alertModal';

// Telas
import Home from './components/pages/home';
import Usuario from './components/pages/usuario';
import AcessoNivel from './components/pages/acessoNivel';
import Acesso from './components/pages/acesso';
import Sessao from './components/pages/sessao';
import EventoSessao from './components/pages/eventoSessao';

// Define as telas e suas URLS
export const TELAS:any = [
    {path:"/home"           ,component:Home},
    {path:"/usuario"        ,component:Usuario},
    {path:"/acessoNivel"    ,component:AcessoNivel},
    {path:"/acesso"         ,component:Acesso},
    {path:"/sessao"         ,component:Sessao},
    {path:"/eventoSessao"   ,component:EventoSessao},
];

// Carrega biblioteca de icones FontAwesome
helpers.loadIcons();

// Seta o idioma padrao do datepicker
registerLocale('ptBR', ptBR);
setDefaultLocale('ptBR');

// Desabilita os logs do audio player
(window as any).soundManager.setup({debugMode: false});

class App extends React.Component<RouteComponentProps> {
    constructor(props: any){
        super(props);

        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log("DEV MODE");
        } else {
            console.log("LIVE MODE");
        }
        
        // Carrega a sessao no cache
        auth.loadSessionCache();

        // Inicializa a class helpers
        helpers.setGlobals(this.alertModal);
    }
    state = {
        centered_mode: true,
        show_topbar: false,
        show_sidemenu: false,
        show_footer: false,

        alertModalOpened:false,
        alertContent: {},

        playAlert: false,
        notifications: [],
        alerts: [],
        //toasts: [],
    }

    // Notificaçoes ----
    checkNotifications = ()=>{
        if(!auth.sessionActive()) return;
        helpers.backendGet('notificacao/notificacao',null,false).then((response)=>{
            let idList = this.state.notifications.map((n:any)=>n.id);
            let playAlert = false;
            for(let i=0;i<response.data.notificacoes.length;i++){
                if(!response.data.notificacoes[i].visualisado && !idList.includes(response.data.notificacoes[i].id)){playAlert=true; break;}
            }
            if(playAlert) this.setState({ notifications: response.data.notificacoes, playAlert:true });
            else this.setState({ notifications: response.data.notificacoes });
        }).catch(err=>{
            if(err.response.status == 401) auth.fetchSession();
            console.log("Falha obtendo notificaçoes: ",err);
        });
    }
    read_notification = (obj:any)=>{
        const data:any = {
            action: 'set_viewed',
            id: obj.id
        }
        axios.post(helpers.backendPath('notificacao/notificacao'),data,{...auth.getAuthOptions()}).then(response=>{
            this.checkNotifications();
        }).catch(err=>{
            if(err.response.status == 401) auth.fetchSession();
            console.log("Falha marcando notificacao como lida: ",err);
        });
    }
    clear_notification = (obj:any)=>{
        const data:any = {
            action: 'deactivate',
            id: obj.id
        }
        axios.post(helpers.backendPath('notificacao/notificacao'),data,{...auth.getAuthOptions()}).then(response=>{
            this.checkNotifications();
        }).catch(err=>{
            if(err.response.status == 401) auth.fetchSession();
            auth.fetchSession();
            console.log("Falha marcando notificacao como lida: ",err);
        });
    }
    //-----------------

    // Alerta Modal ---
    alertModal = (obj:AlertModalContent)=>{
        const {alerts, alertModalOpened}:any = this.state;
        alerts.push(obj);
        if(!alertModalOpened){
            let alertContent = alerts.shift();
            this.setState({alerts, alertContent, alertModalOpened: true});
        } else {
            this.setState({alerts});
        }
    }
    closeAlertModal = ()=>{
        const {alerts}:any = this.state;
        if(this.state.alerts.length>0){
            this.setState({alertModalOpened:false});
            setTimeout(()=>{
                let alertContent = alerts.shift();
                this.setState({alerts, alertContent, alertModalOpened:true});
            },100);
        } else {
            this.setState({alertModalOpened:false});
        }
    }
    alertError = (err:any)=>{        
        let alertContent = {
            title: "Erro",
            body: <Alert variant="danger">{err}</Alert>
        }
        this.alertModal(alertContent);
    }
    //-----------------

    componentDidMount() {
        // Atualiza a state caso tenha carregado o cache
        this.onSessionUpdate();

        auth.addListener(this.onSessionUpdate);
        window.addEventListener("beforeunload", (ev) => {
            const {remember} = auth.getStoredLogin();
            if(remember!=='true'){
                auth.forgetSession();
            }
        });
        
        // Loop que checa notificacoes
        window.setInterval(()=>this.checkNotifications(), 15000);

        /*helpers.onPageFocusChange((evt,hidden)=>{
            let v = "visible", h = "hidden", evtMap:any = { focus:v, focusin:v, pageshow:v, blur:h, focusout:h, pagehide:h };
            evt = evt || window.event;
            if (evt.type in evtMap)
                console.log("mapped",evtMap[evt.type]);
            else
                console.log((document as any)[hidden]);
        })*/
        
        // Atualiza a sessao no plano de fundo se pegou do cache
        if(auth.sessionActive()) auth.fetchSession();
    }  

    onSessionUpdate = () => {
        let new_state = {...this.state};
        const sessionActive = auth.sessionActive();
        new_state.show_topbar = new_state.show_sidemenu = new_state.show_footer = sessionActive;
        new_state.centered_mode = !sessionActive;
        this.setState(new_state);

        this.checkNotifications();
    }

    onClickLogout = async (evt:Event) => {
        evt.preventDefault();
        this.props.history.replace("/");
        await auth.logout();
        auth.fetchSession();
    }

    // Propriedades direcionadas a todas as telas
    screenProps = {
        checkNotifications: this.checkNotifications,
        alertModal: this.alertModal,
        closeAlertModal: this.closeAlertModal,
        alertModalOpened: this.state.alertModalOpened,
        alertError: this.alertError,
    }

    renderContent = () => {
        return (
            <Switch>
                <Route path="/login" render={(props) => <Login {...props} {...this.screenProps} />}/>

                {/* === Telas === */}
                {TELAS.map((tela:any,i:number)=> <ProtectedRoute key={i} path={tela.path} component={tela.component} {...this.screenProps}/>)}
                {/* ============= */}

                <Route path="/404" component={ErrorPages.Error404}/>
                <Route path="/301" component={ErrorPages.Error301}/>
                <Route path="/" exact component={() => <Redirect to="/home"/>} />
                <Redirect to="/404"/>
            </Switch>
        );
    }

    render() { 
        const state = this.state;
        return (
            <>
                {!state.centered_mode && <TopBar notifications={this.state.notifications} onClearNotification={(notif:any)=>this.clear_notification(notif)} onReadNotification={(notif:any)=>this.read_notification(notif)} onClickLogout={this.onClickLogout} />}
                <Container fluid={true}>
                    <Row>
                        {!state.centered_mode && <SideMenu />}
                        <Col {...(!state.centered_mode && {md:9, lg:10, className:"ml-sm-auto px-4 mt-5"})}>
                                {this.renderContent()}
                            {!state.centered_mode && <Footer />}
                        </Col>
                    </Row>
                </Container>

                <AlertModal opened={this.state.alertModalOpened} content={this.state.alertContent} onClose={this.closeAlertModal}/>

                {/* Alerta de notificacao */}
                {state.playAlert &&  <Sound
                    url={notificationSound}
                    playStatus={(Sound as any).status.PLAYING}
                    onFinishedPlaying={()=>this.setState({playAlert: false})} />
                }
            </>
        );
    }
}

export default withRouter(App);