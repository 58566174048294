import React from 'react';
import DateButton from '../common/input/dateButton';
import CPFInput from '../common/input/cpfInput';
import IconSelect from '../common/input/iconSelect';
import { Button, Card, Container, Row, Col, Form } from 'react-bootstrap';

import { Doughnut, Line } from 'react-chartjs-2';
import helpers from '../../services/helpers';
import { AlertModalContent } from './../common/alertModal';

const opts_round = {
    maintainAspectRatio: false,
    position: "bottom",
}
const opts_line = {
    maintainAspectRatio: false,
}

const data1 = {
    labels: [
        'Red',
        'Green',
        'Yellow'
    ],
    datasets: [{
        data: [300, 50, 100],
        backgroundColor: [
        '#FF6384',
        '#36A2EB',
        '#FFCE56'
        ],
        hoverBackgroundColor: [
        '#FF6384',
        '#36A2EB',
        '#FFCE56'
        ]
    }]
};
const data2 = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
        label: 'My First dataset',
        fill: true,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [65, 59, 80, 81, 56, 55, 40]
        }
    ]
};

class Home extends React.Component<any,any> {
    state = {   
        data1: {},
        data2: {},
        areaText:""
    }

    componentDidMount(){
    }

    testBabel = ()=>{
        let jsx = helpers.string2jsx(this.state.areaText);
        let alert:AlertModalContent = {title:"String pra JSX",body: jsx};
        this.props.alertModal(alert);
    }

    render() { 
        const now = new Date;
        return <div className="p-0 mt-4">
                <h1 className="h2">Dashboard</h1>
                
                <Row>
                    <Col className="mt-4" lg={6}>
                        <Card body>
                            <h5>Gráfico redondo</h5>
                            <Row>
                                <Col sm={3}>
                                    <Doughnut data={data1} width={200} height={200} options={opts_round}/>
                                </Col>
                                <Col>
                                    <li>
                                        <ul>lol1</ul>
                                        <ul>lol2</ul>
                                        <ul>lol3</ul>
                                    </li>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col className="mt-4" lg={6}>
                        <Card body>
                            <h5>Gráfico em linha</h5>
                            <div>
                                <Line data={data2} width={200} height={200} options={{ maintainAspectRatio: false }}/>
                            </div>
                        </Card>
                    </Col>
                    <Col className="mt-4" lg={12}>
                        <Card body>
                            <h5>Testes de componentes</h5>
                            <IconSelect/>
                            <div className="my-3">
                                <DateButton />
                            </div>
                            <div className="my-3">
                                <Button variant="outline-danger" onClick={()=>{
                                    helpers.backendGet('login',{debug:true},false).then(r=>{
                                        this.props.checkNotifications();
                                    });
                                }}>Debugar Sessao</Button>
                            </div>
                            <CPFInput className="my-3"/>
                            <h6>String pra JSX</h6>
                            <Form.Control as="textarea" className="my-3" rows="3" placeholder="JSX..." value={this.state.areaText} onChange={(e:any)=>this.setState({areaText:e.currentTarget.value})} />
                            <Button variant="warning" onClick={this.testBabel}>Converter</Button>
                        </Card>
                    </Col>
                </Row>
            </div>
    }
}
 
export default Home;