import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import auth from '../../services/authentication';
import helpers from '../../services/helpers';

interface IProtectedRouteProps {
    path:string;
    component: React.Component;
    render(obj: any):any;
}

const ProtectedRoute = ({path, component: TargetComponent, render, ...params}: any) => {
    return (
    <Route
        {...params}
        render={props => {
            if(!auth.sessionActive()) {
                const desired_path = window.location.pathname;
                helpers.setDesiredPath(desired_path);
                return <Redirect to="/login"/>;
            }
            if(!auth.checkPermission(path)) return <Redirect to="/301"/>;
            return TargetComponent ? <TargetComponent {...props} {...params}/> : render(props);
        }}
    /> );
}
 
export default ProtectedRoute;