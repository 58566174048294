import React from 'react';

import helpers from '../../services/helpers';
import axios from 'axios';

import MUIDataTable from 'mui-datatables';
//import TableRow from "@material-ui/core/TableRow";
//import TableCell from "@material-ui/core/TableCell";

import { Card, Badge, Fade, ProgressBar, Modal, Button, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NodeForm, { NodeFormData } from '../common/nodeForm';
import DropdownIcon from '../common/dropdownIcon';
import auth from '../../services/authentication';
import SpinnerOverlay from '../common/spinnerOverlay';
import NivelInput from './../common/input/nivelInput';
import CheckboxInput from './../common/input/checkboxInput';
import DateButton from './../common/input/dateButton';
import { format } from 'date-fns';
import SearchInput from './../common/input/searchInput';

const options:any = {
    textLabels: helpers.getDatatableLocalization(),
    print:false,
    download:false,
    filter:true,
    search:true,
    viewColumns:false,
    elevation: 0,
    customToolbarSelect: ()=><></>,
};

class Sessao extends React.Component<any,any> {
    _mounted=false;
    state:any = {
        sessionsList:[],
        fetchingData: false,

        startDate: 0,
        endDate: new Date().getTime(),

        modalOpened: false,
        sessionDetails: null,

        spinnerOverlay: false,
        spinnerStatus: null
    }

    componentDidMount(){
        this._mounted=true;
        this.fetchData();
    }
    componentWillUnmount() {this._mounted=false;}

    fetchData = (filters?:any) => {
        if(this._mounted) this.setState({fetchingData: true});
        axios.get(helpers.backendPath("sessao/sessao"),{params: filters,...auth.getAuthOptions()}).then(response=>{
            if(this._mounted) this.setState({sessionsList: response.data.data, fetchingData: false});
        }).catch(err=>{
            auth.fetchSession();
            if(this._mounted){
                this.setState({fetchingData: false,spinnerStatus: "error"});
                setTimeout(()=>{if(this._mounted){
                    this.setState({spinnerOverlay: false});
                    this.props.alertError(<>Erro {err.response.status}: {err.response.statusText}<br/><br/>{err.response.data}</>);
                }}, 1500);
            };
        });
    }

    showSessions = (session:any)=>{
        let sessionDetails = {...session};
        const query = {"ids": session.sessao_list, "list_all":true};
        this.setState({spinnerOverlay: true, spinnerStatus: null});
        axios.get(helpers.backendPath("sessao/sessao"),{params: query,...auth.getAuthOptions()}).then(response=>{
            sessionDetails.list = response.data.data;
            if(this._mounted) this.setState({modalOpened: true, sessionDetails, spinnerOverlay: false});
        }).catch(err=>{
            auth.fetchSession();
            if(this._mounted){
                this.setState({spinnerStatus: "error"});
                setTimeout(()=>{if(this._mounted){
                    this.setState({spinnerOverlay: false});
                    this.props.alertError(<>Erro {err.response.status}: {err.response.statusText}<br/><br/>{err.response.data}</>);
                }}, 1500);
            };
        });
        this.setState({modalOpened: true, sessionDetails});
    }

    closeSession = (session:any, closeUser:boolean)=>{
        console.log(session);
        if(this._mounted) this.setState({spinnerOverlay: true, spinnerStatus: null});
        const data:any = {
            action: closeUser ? "close_user" : "close_session",
            id: closeUser ? session.usuario_id : session.sessao_id
        }
        console.log(data);
        axios.post(helpers.backendPath("sessao/sessao"),data, {...auth.getAuthOptions()}).then(response=>{
            console.log(response.data);
            if(this._mounted){
                this.setState({spinnerStatus: "success"});
                this.fetchData();
                auth.fetchSession();
                setTimeout(()=>{
                    if(this._mounted){
                        this.setState({spinnerOverlay: false});
                        if(!closeUser) this.showSessions(this.state.sessionDetails);
                    }
                }, 1000);
            };
        }).catch(err=>{
            auth.fetchSession();
            if(this._mounted){
                this.setState({spinnerStatus: "error"});
                setTimeout(()=>{if(this._mounted){
                    this.setState({spinnerOverlay: false});
                    this.props.alertError(<>Erro {err.response.status}: {err.response.statusText}<br/><br/>{err.response.data}</>);
                }}, 1500);
            };
        });
    }

    render() {
        const blank={options:{empty:true,filter:false,sort:false,searchable:false,viewColumns:false,print:false,download:false}};

        let rowActions = [{label: "Mostrar sessões", onClick:(ref:any)=>this.showSessions(this.state.sessionsList[ref.rowIndex])}];
        if(auth.checkPermission('/eventoSessao')) rowActions.push({label: "Atividades do usuário", onClick:(ref:any)=>{
            const item = this.state.sessionsList[ref.rowIndex];
            this.props.history.push('/eventoSessao',{"usuario": item.usuario_nome});
        }});
        if(auth.checkPermission('/sessao/gerenciar')) rowActions.push({label: "Encerrar Todas", onClick:(ref:any)=>{
            const item = this.state.sessionsList[ref.rowIndex];
            let alertContent = {
                title: `Encerrar sessões do usuário "${item.usuario_nome}"`,
                body: <p>Deseja encerrar todas as sessões do usuário "{item.usuario_nome}"?<br/>O usuário será enviado à pagina de login.</p>,
                buttons: [
                    {label: "Encerrar", variant: "danger", action: ()=>{this.closeSession(item, true); this.props.closeAlertModal();}},
                    {label: "Cancelar", variant: "secondary", action: this.props.closeAlertModal},
                ]
            }
            this.props.alertModal(alertContent);
        }});

        let rowActionsDetail:any[] = [{label: "Encerrar", onClick:(ref:any)=>{
            const item = this.state.sessionDetails.list[ref.rowIndex];
            let alertContent = {
                title: `Encerrar sessão do usuário "${item.usuario_nome}"`,
                body: <p>Deseja encerrar esta sessão do usuário "{item.usuario_nome}"?</p>,
                buttons: [
                    {label: "Encerrar", variant: "danger", action: ()=>{this.closeSession(item, false); this.props.closeAlertModal();}},
                    {label: "Cancelar", variant: "secondary", action: this.props.closeAlertModal},
                ]
            }
            this.props.alertModal(alertContent);
        }}];
        if(auth.checkPermission('/eventoSessao')) rowActionsDetail.push({label: "Atividades da sessão", onClick:(ref:any)=>{
            const item = this.state.sessionDetails.list[ref.rowIndex];
            this.props.history.push('/eventoSessao',{"sessao": item.sessao_id});
        }});

        const dataTableColumns:any = [
            {label: 'Online',name: 'sessao_ativo',options:{customBodyRender:(value:any)=>value && <FontAwesomeIcon color={value=='1'?"green":"gray"} icon="circle" style={{width:"15px",height:"15px"}}></FontAwesomeIcon>}},
            {label: 'Sessoes Ativas',name: 'sessao_count'},
            {label: 'Usuário',name: 'usuario_nome'},
            {label: 'Nível',name: 'nivel_nome',options:{customBodyRender:(value:any)=>value && <Badge variant="light" style={{fontSize:"small"}}>{value}</Badge>}},
            {label: 'Entrada',name: 'sessao_timestamp',options:{customBodyRender:(value:any)=>{
                const [hora,data] = format(new Date(value*1000),'HH:mm|dd/MM/yy').split('|');
                return <>
                    <Badge style={{fontSize:"small"}}><FontAwesomeIcon className="mx-1" icon="clock" color="lightgray" />{hora}</Badge>
                    <Badge style={{fontSize:"small"}}><FontAwesomeIcon className="mx-1" icon="calendar-alt" color="lightgray" />{data}</Badge>
                </>;
            }}},
            {label: 'IP',name: 'sessao_ip'},
            {label: 'Ações',options:{customBodyRender: (value:any,tableMeta:any)=><DropdownIcon id={tableMeta.rowIndex} refValue={tableMeta} items={rowActions}/>}}
        ];
        let detailsTableColumns:any[] = [...dataTableColumns];
        detailsTableColumns.splice(1,1);
        detailsTableColumns[detailsTableColumns.length-1] = auth.checkPermission('/sessao/gerenciar') ? {label: 'Ações',options:{customBodyRender: (value:any,tableMeta:any)=><DropdownIcon id={tableMeta.rowIndex} refValue={tableMeta} items={rowActionsDetail}/>}} : blank;
        const tableOptions:any = {
            ...options,
            selectableRows: false
        };
        
        const dateFieldProps = {
            showMonthDropdown:true,
            showYearDropdown:true,
            dropdownMode:"select",
            showTimeSelect: true,
            timeFormat:"HH:mm",
            timeIntervals:15,
            dateFormat:"dd/MM/yy HH:mm",
            valueFormat:"timestamp",
            timeCaption:"Hora",
            minDate: 0,
            maxDate: Math.min(new Date().getTime()),
        }

        // Form Pesquisa
        const filterForm:NodeFormData = {
            fields:[
                //{label:"Usuário", name:"usuario", type: "text", defaultValue: "", labelClasses:"col-1", fieldClasses:"col-6"},
                {label:"Usuário", name:"usuario", type: SearchInput, props:{uri:'usuario/usuario',dataPath:'data',valuePath:'nome',placeholder: ""}, defaultValue: "", labelClasses:"col-1", fieldClasses:"col-6"},
                {label:"Nivel", name:"nivel", type: NivelInput, props:{extraOptions:[{label: "Todos",value:""}]}, defaultValue:"", labelClasses:"col-1", fieldClasses:"col-2"},
                {label:"A partir de", name:"inicio", type: DateButton, props: {...dateFieldProps, selectsStart:true, startDate:this.state.startDate, endDate:this.state.endDate}, defaultValue: null, placeholder: "Qualquer data", labelClasses:"col-1"},
                {label:"Até", name:"fim", type: DateButton, props: {...dateFieldProps, selectsEnd:true, startDate:this.state.startDate, endDate:this.state.endDate, minDate: this.state.startDate}, defaultValue: null, placeholder: "Agora", labelClasses:"col-1"},
                {label:null, name:"ativo", type: CheckboxInput, props:{label:"Mostrar apenas sessões ativas no momento"}, defaultValue: false, labelClasses:"col-1"},
            ],
            buttons: [
                {label: "Atualizar", variant: "primary", isSubmit: true, action: (form: NodeForm)=>{
                    const filters = form.getValues();
                    filters.inicio = Math.round(filters.inicio/1000);
                    filters.fim = Math.round(filters.fim/1000);
                    this.fetchData(filters);
                }},
                {label: "Limpar", variant: "secondary", action: async (form: NodeForm)=>{
                    await form.clear();
                    const filters = form.getValues();
                    this.fetchData(filters);
                }}
            ],
            buttonClasses:"offset-1",
            onChange: form=>{
                this.setState({startDate: form.getFieldValue('inicio'),endDate: form.getFieldValue('fim')});
            },
            initialState: this.props.history.location.state
        };

        return <>
            <Card className="mt-4" >
                <h5 className="m-2 mx-4">Filtrar Sessões</h5>
                <NodeForm formData={filterForm}/>
                <hr className="m-0"/>
                <Fade in={this.state.fetchingData}><div>
                    <ProgressBar style={{height:"8px"}} animated now={100}/>
                </div></Fade>
                <MUIDataTable title={"Sessões"} columns={dataTableColumns} data={this.state.sessionsList} options={tableOptions}/>
            </Card>

            {this.state.sessionDetails &&
                <Modal centered dialogClassName="fullscreen-modal" hidden={this.props.alertModalOpened} show={this.state.modalOpened} onHide={()=>this.setState({modalOpened: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>{`Sessões do usuário "${this.state.sessionDetails.usuario_nome}"`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container style={{maxWidth: "none"}}>
                            <MUIDataTable title={null} columns={detailsTableColumns} data={this.state.sessionDetails.list} options={tableOptions}/>
                        </Container>
                    </Modal.Body>
                </Modal>
            }

            {this.state.spinnerOverlay ? <SpinnerOverlay status={this.state.spinnerStatus}/> : null }
        </>
    }
}
 
export default Sessao;