import React from 'react';
import { Form } from 'react-bootstrap';
import validation from '../../../services/validation';
import ReactInputMask from 'react-input-mask';

export interface CPFInputProps {
    onChange?:(evt:any)=>any;
    [x:string]:any;
}

const mask = '999.999.999-99';

class CPFInput extends React.Component<CPFInputProps, any> {
    state = {
        value: ''
    }

    handleChange = (evt:any)=>{
        let value = evt.target.value;
        if(this.props.onChange){
            let newobj = {...this.props};
            newobj.value = value;
            newobj.type = "cpf";
            const newevt = {currentTarget: newobj, target: newobj, type: "cpf"};
            this.props.onChange(newevt);
        }
        this.setState({value: value});
    }

    render() { 
        const {value,onChange,defaultValue,...others} = this.props;
        return <ReactInputMask mask={mask} maskChar="_" value={this.state.value} alwaysShowMask onChange={this.handleChange} {...others}>
            {(inputProps:any) => <Form.Control {...inputProps} type="text"/>}
        </ReactInputMask>;
    }
}
 
export default CPFInput;