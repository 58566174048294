import React from 'react';
import helpers from '../../../services/helpers';
import SelectAsync from 'react-select/lib/Async';
import { SelectComponentsProps } from 'react-select/lib/Select';

const URI = "acessoNivel/acessoNivel";

class AcessoInput extends React.Component<SelectComponentsProps,any> {
    // Propriedades
    _mounted=false;
    _lastCall:NodeJS.Timeout|null = null;
    _ready = false;
    _searchStack:Array<number> = [];
    _currentOptions:Array<any> = [];

    _defaultSet = false;
    fieldRef:any = React.createRef();
    //
    componentDidMount() {this._mounted=true;}
    componentWillUnmount() {this._mounted=false;}

    getOptions = async (searchTerm:string)=>{
        let searchID = this._searchStack.length;
        this._searchStack.push(searchID);

        if(this._lastCall!=null){
            clearTimeout(this._lastCall);
            this._lastCall=null;
        }
        let hasCache = helpers.isCached(URI);
        let data:Array<any> = [];
        this._lastCall = setTimeout(()=>{
            this._ready=false;
            helpers.backendGet(URI).then((result:any)=>{
                if(this._searchStack[this._searchStack.length-1]!==searchID) return;
                data = result.data.acesso.map((acesso:any)=>({label: acesso.label, value: acesso.id}));
                this._ready=true;
            });
        },hasCache?0:800);
        let canceled = false;
        await helpers.waitUntil(()=>{
            let isLatest = this._searchStack[this._searchStack.length-1]===searchID;
            if(!isLatest) canceled=true;
            return this._ready || !isLatest;
        });
        this._searchStack.splice(this._searchStack.indexOf(searchID),1);
        if(canceled) return;
        if(this.props.extraOptions) data = [...this.props.extraOptions,...data]; // Adiciona as opçoes extra se existir

        data = data.filter((val:any)=>(helpers.lazyCompare(val.label,searchTerm) || helpers.lazyCompare(val.value,searchTerm)));
        this._currentOptions = data;
        this._ready=false;
        if(!this._defaultSet){
            this._defaultSet=true;
            this.onChange({value: this.props.defaultValue},null);
        }
        return this._currentOptions;
    }

    onChange = (value:any,action:any)=>{
        if(this.props.onChange){
            let newobj = {...this.props};
            newobj.value = value.value;
            this.props.onChange({target: newobj});
        }
    }

    setValue = (value:any)=>{
        const optionValue = this._currentOptions.find((opt:any)=>opt.value===value);
        return optionValue;
    }

    render() {
        const {value,defaultOptions=true,onChange, isValid, isInvalid,...props} = this.props;
        return <SelectAsync ref={this.fieldRef} className="small" loadOptions={this.getOptions} value={this.setValue(value)} defaultOptions={defaultOptions} onChange={this.onChange} {...props} {...helpers.getSelectionLocalization(isInvalid?false:(isValid?true:null))}/>;
    }
}
 
export default AcessoInput;